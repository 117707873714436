
import React from "react";

import { Divider } from "primereact/divider";
function DetailContainer({data, style}) {
    return <div style={ style ? { ...style, marginTop: "20px", marginBottom: "20px" } : { marginTop: "20px", marginBottom: "20px" } }>
        {data && data.map( (el, index) =>{
            return (<>
            <Detail label={el?.label} value={el?.value}/>
            {index !== (data?.length - 1) && <Divider style={{margin: "0"}} layout="horizontal"/>}
            </>)
        })}
    </div>
}
function Detail({label, value}) {
    return <div style={{display: 'flex', alignItems: "center"}}>
        <p style={{width: '30%', maxWidth: '100px', margin: '0'}}>{label}</p>
        <Divider layout="vertical"/>
        <p style={{width: '70%', margin: '0'}}>{value || 'Inconnu'}</p>
    </div>
}

export {DetailContainer, Detail}