import React  from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { signOut } from 'firebase/auth'
import { auth } from './utils/firebase';

export const AppTopbar = (props) => {
    const history = useHistory()

    const logout = () => {
        signOut(auth);
    }

    console.log("history: ", history)

    return (
        <div className="layout-topbar">
            { history.location.pathname !== "/" && <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={() => history.goBack()}>
                <i className="pi pi-chevron-left"/>
            </button>}

            <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === 'light' ? 'images/logo-dark.png' : 'images/logo-white.png'} alt="logo"/>
                <span>Dashboard Johayo</span>
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-calendar"/>
                            <span>Events</span>
                        </button>
                    </li>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-cog"/>
                            <span>Settings</span>
                        </button>
                    </li>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={() => logout()}>
                            <i className="pi pi-ban"/>
                            <span>Logout</span>
                        </button>
                    </li>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-user"/>
                            <span>Profile</span>
                        </button>
                    </li>
                </ul>
        </div>
    );
}
