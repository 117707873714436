export const getWeekFromDate = (date) => {
    const currentdate = new Date(date)
    const oneJan = new Date(currentdate.getFullYear(), 0, 1)
    const numberOfDays = Math.floor(
      (currentdate - oneJan) / (24 * 60 * 60 * 1000)
    );
    const result = Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
  
    return result;
  };
export const unique = (arr, key) => {
  const keys = new Set();
  return arr.filter(el => !keys.has(el[key]) && keys.add(el[key]));
};
  