import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";

import { NotificationsService } from "../../service/NotificationsServices";
import { IdolsService } from "../../service/IdolsService";

import ModalGallery from "../modals/ModalGallery";

const LanguageOptions = [
    {
        label: "All",
        value: "all",
    },
    {
        label: "Anglais",
        value: "en",
    },
    {
        label: "Francais",
        value: "fr",
    },
];

const ModalNotificationCreator = ({ visible, callback }) => {
    const idolsService = new IdolsService();
    const notificationsService = new NotificationsService();
    const toast = useRef();
    const timer = useRef(null);

    const [image, setImage] = useState("");
    const [date, setDate] = useState("");
    const [dateType, setDateType] = useState("");
    const [language, setLanguage] = useState(LanguageOptions[0]);
    const [selectedIdol, setSelectedIdol] = useState("");
    const [target, setTarget] = useState("");
    const [targetType, setTargetType] = useState("");
    const [searchIdolInput, setSearchIdolInput] = useState("");
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [filterIdols, setFilterIdols] = useState([]);
    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const searchIdolsGraphQL = idolsService.useSearchQueryGraphQL({ filters: filterIdols, rows: 20, operator: "or" });
    const [addNotificationsGraphQL, hookAddNotification] = notificationsService.useAddNotificationGraphQL();

    const idols = searchIdolsGraphQL?.data?.searchIdols;

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            const filtersTmp = [];
            filtersTmp.push({ field: "displayName", value: searchIdolInput });
            filtersTmp.push({ field: "realName", value: searchIdolInput });
            filtersTmp.push({ field: "group", value: searchIdolInput });
            setFilterIdols(filtersTmp);
        }, 1000);
        return () => clearTimeout(timer.current);
    }, [searchIdolInput]);

    useEffect(() => {
        setTarget(selectedIdol.id);
    }, [selectedIdol]);

    const onCreateNotification = () => {
        const date = new Date(date);
        const newNotification = {
            image: image,
            title: title,
            message: message,
            creationDate: Date.now(),
            date: date.getTime(),
            target: target,
            targetType: targetType,
            language: language,
        };
        addNotificationsGraphQL({ variables: { notification: newNotification } })
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Successful", detail: "Idol added", life: 2000 });
                callback(false);
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error.message, life: 3000 });
            });
    };

    return (
        <>
            <Sidebar visible={visible} onHide={() => callback(false)} baseZIndex={1000} fullScreen>
                <h1 style={{ fontWeight: "normal" }}>Création d'une notification</h1>
                <div className="col-12">
                    <div className="card">
                        <div className="grid p-fluid mt-3">
                            <div className="field col-12 md:col-4">
                                <div className="field">
                                    <label htmlFor="title">Titre</label>
                                    <InputText type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} className={!!title ? "" : "p-invalid"} />
                                </div>
                            </div>
                            <div className="field col-12 md:col-4">
                                <div className="field">
                                    <label htmlFor="title">Message</label>
                                    <InputText type="text" id="message" value={message} onChange={(e) => setMessage(e.target.value)} className={!!message ? "" : "p-invalid"} />
                                </div>
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="avatar">Image</label>
                                <Button label="Choisir" icon="pi pi-image" onClick={() => setShowGalleryModal(true)} loading={false} iconPos="right" className="mr-2 mb-2" />
                                {!!image && <img src={image} style={{ maxHeight: "200px" }} />}
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="language">Language</label>
                                <Dropdown id="language" options={LanguageOptions} value={language.value} onChange={(e) => setLanguage(e.value)} optionLabel={language.label} className={!!language ? "ml-2" : "p-invalid ml-2"} />
                            </div>
                            <div className="col-12 md:col-8"></div>
                            <div className="col-12 md:col-4">
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div className="field-radiobutton">
                                        <label htmlFor="ALL">All</label>
                                        <RadioButton inputId="ALL" name="ALL" value="ALL" checked={targetType === "ALL"} onChange={(e) => setTargetType(e.target.value)} className={!!targetType ? "ml-2" : "p-invalid ml-2"} />
                                    </div>
                                    <div className="field-radiobutton ml-8">
                                        <label htmlFor="BYIDOL">By idol</label>
                                        <RadioButton inputId="BYIDOL" name="BYIDOL" value="BYIDOL" checked={targetType === "BYIDOL"} onChange={(e) => setTargetType(e.target.value)} className={!!targetType ? "ml-2" : "p-invalid ml-2"} />
                                    </div>
                                </div>
                            </div>

                            <div className="field col-12 md:col-8">
                                {targetType === "BYIDOL" && (
                                    <>
                                        <label htmlFor="target">Idol</label>
                                        <Dropdown id="target" options={idols} value={selectedIdol.displayName} onChange={(e) => setSelectedIdol(e.value)} optionLabel="target" className={!!selectedIdol ? "ml-2" : "p-invalid ml-2"} />
                                    </>
                                )}
                            </div>

                            <div className="col-12 md:col-4">
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div className="field-radiobutton">
                                        <label htmlFor="ALL">Now</label>
                                        <RadioButton inputId="NOW" name="NOW" value="NOW" checked={dateType === "NOW"} onChange={(e) => setDateType(e.target.value)} className={!!dateType ? "ml-2" : "p-invalid ml-2"} />
                                    </div>
                                    <div className="field-radiobutton ml-8">
                                        <label htmlFor="BYDATE">By Date</label>
                                        <RadioButton inputId="BYDATE" name="BYDATE" value="BYDATE" checked={dateType === "BYDATE"} onChange={(e) => setDateType(e.target.value)} className={!!dateType ? "ml-2" : "p-invalid ml-2"} />
                                    </div>
                                </div>
                            </div>

                            <div className="field col-12 md:col-8">
                                {dateType === "BYDATE" && (
                                    <>
                                        <label htmlFor="date">Date</label>
                                        <InputMask id="date" mask="9999/99/99" value={date} onChange={(e) => setDate(e.value)} className={!!date ? "" : "p-invalid"} />
                                    </>
                                )}
                            </div>
                        </div>
                        <div>
                            <Button label="Annuler" icon="pi pi-times" onClick={() => callback(false)} iconPos="right" className="mr-2 mb-2 p-button-danger" />
                            <Button label="Créer" icon="pi pi-check" onClick={() => onCreateNotification()} loading={false} iconPos="right" className="mr-2 mb-2" />
                        </div>
                    </div>
                </div>
            </Sidebar>
            <ModalGallery
                visible={showGalleryModal}
                callback={(value) => {
                    if (!value) {
                        setShowGalleryModal(false);
                    } else {
                        setImage(value?.url);
                        setShowGalleryModal(false);
                    }
                }}
            />
            <Toast ref={toast} />
        </>
    );
};

export default ModalNotificationCreator;
