import React, { useState, useRef, useEffect } from "react";

import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { Galleria } from "primereact/galleria";
import { Image } from "primereact/image";
import { Toast } from "primereact/toast";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";

import { AssetsService } from "../service/AssetsService";

const galleriaResponsiveOptions = [
    {
        breakpoint: "1024px",
        numVisible: 5,
    },
    {
        breakpoint: "960px",
        numVisible: 4,
    },
    {
        breakpoint: "768px",
        numVisible: 3,
    },
    {
        breakpoint: "560px",
        numVisible: 1,
    },
];

const GalleryPage = () => {
    const assetsService = new AssetsService();
    const [newDirectory, setNewDirectory] = useState(false);
    const [currentPath, setCurrentPath] = useState("gallery");
    const [newAssets, setNewAssets] = useState(false);
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [typePreview, setTypePreview] = useState("thumbnail")
    const op = useRef(null);

    const [layout, setLayout] = useState("grid");
    const toast = useRef(null);
    const [fileName, setFileName] = useState("");
    const [files, setFiles] = useState([]);
    const [assets, setAssets] = useState([]);
    const useGallery = assetsService.useFetchGallery(currentPath);
    const useAddDirectory = assetsService.usePushNewDirectory();
    const useAddAssets = assetsService.useAddAssets();

    const onChange = (values) => {
        const newFiles = [];
        const files = [...values];
        setAssets(files)
        if (files.length >= 0) {
            files.map((file) => {
                newFiles.push({
                    title: file.name,
                    itemImageSrc: URL.createObjectURL(file),
                    thumbnailImageSrc: URL.createObjectURL(file),
                    alt: file.name,
                });
            });
            setFiles(newFiles);
        }
    };

    const onAddAssets = () => {
        useAddAssets.mutate(
            { path: currentPath, assets: assets },
            {
                onSuccess: () => {
                    setNewAssets(false);
                    useGallery.refetch();
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Assets Added", life: 2000 });
                },
                onError: (err) => {
                    setNewAssets(false);
                    toast.current.show({ severity: "error", summary: "Error", detail: err?.message, life: 2000 });
                },
            }
        );
    };

    const onAddDirectory = () => {
        useAddDirectory.mutate(
            { path: currentPath, label: fileName },
            {
                onSuccess: () => {
                    setNewDirectory(false);
                    useGallery.refetch();
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Directory Added", life: 2000 });
                },
                onError: (err) => {
                    setNewDirectory(false);
                    toast.current.show({ severity: "error", summary: "Error", detail: err?.message, life: 2000 });
                },
            }
        );
    };

    const updateBreadCrumb = (label) => {
        const splitedPath = currentPath.split("/");
        const index = splitedPath.findIndex((el) => el === label);
        const newPath = splitedPath.slice(0, index + 1).join("/");
        setCurrentPath(newPath);
    };

    useEffect(() => {
        const splitedPath = currentPath.split("/");
        const items = [];
        splitedPath.map((p) => {
            items.push({ label: p, command: (event) => updateBreadCrumb(event.item.label) });
        });
        setBreadcrumbItems(items);
    }, useGallery?.data?.data);

    const galleriaItemTemplate = (file) => <img src={file.itemImageSrc} alt={file.alt} style={{ maxHeight: "500px", display: "block" }} />;
    const galleriaThumbnailTemplate = (file) => <img src={file.thumbnailImageSrc} alt={file.alt} style={{ maxHeight: "100px", display: "block" }} />;
    const modalAddImage = () => (
        <Sidebar visible={newAssets} onHide={() => setNewAssets(false)} baseZIndex={1001} fullScreen>
            <h1 style={{ fontWeight: "normal" }}>Ajout image</h1>
            <div className="col-12">
                <div className="card">
                    <div className="grid p-fluid mt-3">
                        <div className="field col-12 md:col-6">
                            <label for="uploadFile" class="label-file">
                                Choisir une image
                            </label>
                            <input id="uploadFile" accept="image/*" className="input-file" multiple={true} type="file" onChange={(event) => onChange(event.target.files)} />
                        </div>
                    </div>
                    {files.length > 0 && (
                        <div className="col-12">
                            <div className="card">
                                <h5>Galleria</h5>
                                <Galleria value={files} responsiveOptions={galleriaResponsiveOptions} numVisible={7} circular style={{ maxWidth: "500px", margin: "auto" }} item={galleriaItemTemplate} thumbnail={galleriaThumbnailTemplate} />
                            </div>
                        </div>
                    )}
                    <div>
                        <Button label="Annuler" icon="pi pi-times" onClick={() => setNewAssets(false)} iconPos="right" className="mr-2 mb-2 p-button-danger" />
                        <Button label="Ajouter" icon="pi pi-check" onClick={() => onAddAssets()} loading={false} iconPos="right" className="mr-2 mb-2" />
                    </div>
                </div>
            </div>
        </Sidebar>
    );

    const createNewFileContainer = () => (
        <div className="card">
            <h5>Nouveau dossier</h5>
            <div className="formgroup-inline">
                <div className="field">
                    <label htmlFor="fileName" className="p-sr-only">
                        File Name
                    </label>
                    <InputText id="fileName" type="text" placeholder="Entre file name" value={fileName} onChange={(e) => setFileName(e.target.value)} className={!!fileName ? "" : "p-invalid"} />
                </div>
                <Button label="Confirmer" onClick={() => onAddDirectory()} />

                <Button
                    label="Annuler"
                    className="ml-2 mb-2 p-button-danger"
                    onClick={() => {
                        setNewDirectory(false);
                    }}
                />
            </div>
        </div>
    );
    const dataviewHeader = (
        <div className="grid grid-nogutter">
            <div className="col-3">
                <span className="p-input-icon-right">
                    <InputText type="text" placeholder="Search" onChange={(e) => setSearchInput(e.target.value)} />
                    <i className="pi pi-search" />
                </span>
            </div>
            <div className="col-6"></div>
            <div className="col-3" style={{ textAlign: "right" }}>
                <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
            </div>
        </div>
    );

    const itemTemplate = (data, layout) => {
        if (!data) {
            return;
        }
        if (layout === "list") {
            return dataviewListItem(data);
        } else if (layout === "grid") {
            return dataviewGridItem(data);
        }
    };

    const onDocClick = (data) => {
        if (data?.type === "directory") {
            setCurrentPath(data?.directory);
        }
    };

    const dataviewListItem = (data) => {
        return (
            <div className="col-12" onClick={() => onDocClick(data)}>
                {data.type === "directory" && (
                    <div className="flex flex-column md:flex-row align-items-center p-3 w-full" style={{ cursor: "pointer" }}>
                        <img src={"assets/file.png"} style={{ maxWidth: "20%", maxHeight: "30px", marginRight: "30px" }} />
                        <p>{data.name}</p>
                    </div>
                )}
                {data.type !== "directory" && (
                    <div className="flex flex-column md:flex-row align-items-center w-full" style={{ cursor: "pointer" }}>
                        <img src={data.thumbnail} style={{ maxWidth: "20%", maxHeight: "50px", margin: "30px" }} />
                        <p>{data.name}</p>
                    </div>
                )}
            </div>
        );
    };

    const dataviewGridItem = (data) => {
        const src = {
            thumbnail: data.thumbnail,
            url: data.url
        }
        return (
            <div className="col-12 md:col-2" onClick={() => onDocClick(data)}>
                {data.type === "directory" && (
                    <div style={{ cursor: "pointer" }}>
                        <img src={"assets/file.png"} className="my-4 md:my-0 w-9 " />
                        <p>{data.name}</p>
                    </div>
                )}
                {data.type !== "directory" && (
                    <div className="card m-3 border-1 surface-border flex flex-column align-items-center" style={{ cursor: "pointer" }}>
                        
                        <Image src={src[typePreview]} onShow={()=> setTypePreview("url") } onHide={() => setTypePreview("thumbnail")} preview  alt="Image Text" style={{ maxWidth: "90%", maxHeight: "90%" }} />
                        <p>{data.name}</p>
                    </div>
                )}
            </div>
        );
    };
    const finderContainer = () => (
        <div className="grid mt-2">
            <div className="col-12">
                <DataView value={!!searchInput ? useGallery?.data?.data.filter((el) => el?.name?.toLowerCase()?.includes(searchInput.toLowerCase())) : useGallery?.data?.data} layout={layout} rows={9} itemTemplate={itemTemplate} header={dataviewHeader} />
            </div>
        </div>
    );

    const breadcrumbHome = { icon: "pi pi-home", command: () => setCurrentPath("gallery") };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card card-w-title">
                    <h5>Gallery</h5>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-2">
                            <Button icon="pi pi-plus" style={{ marginRight: ".5em" }} label="Créer un dossier" className="mr-2 mb-2" onClick={() => setNewDirectory(!newDirectory)}></Button>
                        </div>
                        <div className="field col-12 md:col-2">
                            <Button icon="pi pi-plus" style={{ marginRight: ".5em" }} label="Ajouter images" className="mr-2 mb-2" onClick={() => setNewAssets(true)}></Button>
                        </div>
                    </div>

                    {newDirectory && createNewFileContainer()}
                    <BreadCrumb home={breadcrumbHome} model={breadcrumbItems} onClick={(e) => console.log("EEEEE: ", e)} />
                    {finderContainer()}
                </div>
            </div>

            {modalAddImage()}
            <Toast ref={toast} />
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(GalleryPage, comparisonFn);
