import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UsersService } from "../../service/UsersService";

const UsersPage = () => {
    const usersService = new UsersService();
    const [searchUserInput, setSearchUserInput] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentRows, setCurrentRows] = useState(20);
    const [sortUsers, setSortUsers] = useState({ field: "creationDate", value: "desc" });
    const [filterUsers, setFilterUsers] = useState({});
    const searchUsersGraphQL = usersService.useGetUsersQuery({ filters: filterUsers, sort: sortUsers, rows: currentRows, page: currentPage });

    const users = searchUsersGraphQL?.data?.getUsers;

    console.log("users: ", users)


    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Users Page</h5>
                    <p>Use this page to start from scratch and place your custom content.</p>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(UsersPage, comparisonFn);
