import { gql, useQuery, useMutation } from "@apollo/client";

const GET_LADDER = gql`
    query GetIdolById($id: ID!) {
        getIdolById(id: $id) {
            id
            avatar
            birthDay
            birthMonth
            birthWeek
            birthYear
            creationDate
            dateOfBirth
            likes
            displayName
            group
            realName
            sexe
        }
    }
`;
const ADD_LADDER = gql`
    mutation addIdol($idol: IdolInput) {
        addIdol(idol: $idol){
            id
            avatar
            birthDay
            birthMonth
            birthWeek
            birthYear
            creationDate
            dateOfBirth
            likes
            displayName
            group
            realName
            sexe
        }
    }
`;
const UPDATE_LADDER = gql`
    mutation updateIdol($id: ID!,$idol: IdolInput) {
        updateIdol(id: $id, idol: $idol){
            id
            avatar
            birthDay
            birthMonth
            birthWeek
            birthYear
            creationDate
            dateOfBirth
            likes
            displayName
            group
            realName
            sexe
        }
    }
`;


const DELETE_LADDER = gql`
    mutation deleteIdol($id: ID!) {
        deleteIdol(id: $id)
    }
`;

const SEARCH_LADDERS = gql`
    query SearchIdols($filters: [Filter], $page: Int, $rows: Int, $sort: Sort, $operator: String) {
        searchIdols(filters: $filters, page: $page, rows: $rows, sort: $sort, operator: $operator) {
            total
            result {
                id
                avatar
                birthDay
                birthMonth
                birthWeek
                birthYear
                creationDate
                dateOfBirth
                likes
                displayName
                group
                realName
                sexe
            }
        }
    }
`;

export class LaddersService {
    useSearchQueryGraphQL = ({ filters, page, rows, sort, operator }) => {
        return useQuery(SEARCH_LADDERS, {
            variables: {
                filters,
                page,
                rows,
                sort,
                operator,
            },
        });
    };
    useGetLadderByIdQueryGraphQL = (id) => {
        return useQuery(GET_LADDER, {
            variables: {
                id,
            },
        });
    };
    useDeleteLadderGraphQL = (id) => {
        return useMutation(DELETE_LADDER);
    };
    useAddLadderGraphQL = () => {
        return useMutation(ADD_LADDER);
    };

    useUpdateLadderGraphQL = () => {
        return useMutation(UPDATE_LADDER);
    };
}
