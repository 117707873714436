import React, { useState, useEffect, useRef } from 'react';
import { Chart } from 'primereact/chart';
import { useHistory } from 'react-router-dom';
import { Avatar } from '../customComponents/components/Avatar';

import { IdolsService } from "../service/IdolsService";
import { GroupsService } from "../service/GroupsService";
import { UsersService } from "../service/UsersService";

const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: '#2f4860',
            borderColor: '#2f4860',
            tension: .4
        },
        {
            label: 'Second Dataset',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: '#00bb7e',
            borderColor: '#00bb7e',
            tension: .4
        }
    ]
};

const HomePage = (props) => {
  
    const [lineOptions, setLineOptions] = useState(null)
    const idolsService = new IdolsService();
    const groupsService = new GroupsService();
    const usersService = new UsersService();
    const history = useHistory()
    const [sortIdols, setSortIdols] = useState({ field: "followers", value: "desc" });
    const [sortGroups, setSortGroups] = useState({ field: "followers", value: "desc" });

    const searchIdolsGraphQL = idolsService.useGetIdolsQuery({ filters: {}, sort: sortIdols, rows: 10  });
    const searchGroupsGraphQL = groupsService.useGetGroupsQuery({ filters: {}, sort: sortIdols, rows: 10  });
    const searchUsersGraphQL = usersService.useGetUsersQuery({ filters: {}, rows: 10  });
    const idols = searchIdolsGraphQL?.data?.getIdols;
    const groups = searchGroupsGraphQL?.data?.getGroups;
    const users = searchUsersGraphQL?.data?.getUsers;

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#ebedef'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
                y: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    useEffect(() => {
        if (props.colorMode === 'light') {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
    }, [props.colorMode]);

    console.log("idols: ", idols )
    console.log("groups: ", groups )

    return (
        <div className="grid">
            <div className="col-12 lg:col-6 xl:col-3" onClick={()=> history.push("/users")}>
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Users</span>
                            <div className="text-900 font-medium text-xl">{users?.total}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-inbox text-cyan-500 text-xl"/>
                        </div>
                    </div>
                    <span className="text-green-500 font-medium">520  </span>
                    <span className="text-500">newly registered</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3"  onClick={()=> history.push("/idols")}>
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Idols</span>
                            <div className="text-900 font-medium text-xl">{idols?.total}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-inbox text-cyan-500 text-xl"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3"  onClick={()=> history.push("/groups")}>
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Groups</span>
                            <div className="text-900 font-medium text-xl">{groups?.total}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-inbox text-cyan-500 text-xl"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3"  onClick={()=> history.push("/ladders")}>
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Ladder</span>
                            <div className="text-900 font-medium text-xl">28441</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-inbox text-cyan-500 text-xl"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 xl:col-3"/>
          
            <div className="col-12 xl:col-6">
                <div className="card">
                    <h5>Users Overview</h5>
                    <Chart type="line" data={lineData} options={lineOptions} />
                </div>
            </div>
            <div className="col-12 xl:col-3"/>
                
                

            <div className="col-12 xl:col-6">
                <div className="card">
                    <div className="flex justify-content-between align-items-center mb-5">
                        <h5>Top Idols </h5>
                    </div>
                    <ul className="list-none p-0 m-0">
                        {
                            idols?.result && idols?.result.map(idol => {
                                return (<li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4" onClick={()=> history.push("/idol/" + idol?.id)}>
                                <Avatar src={idol?.avatar} alt={idol?.displayName} size={50}/>
                                <div>
                                    <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{idol?.displayName}</span>
                                    <div className="mt-1 text-600">{idol?.group}</div>
                                </div>
                                <div className="mt-2 md:mt-0 flex align-items-center">
                                    <span className="text-500 ml-3 font-medium">{idol?.followers || 0 + " Followers"}</span>
                                </div>
                            </li>)
                            })
                        }
                        
                        
                    </ul>
                </div>
            </div>
            <div className="col-12 xl:col-6">
                <div className="card">
                    <div className="flex justify-content-between align-items-center mb-5">
                        <h5>Top Groups </h5>
                    </div>
                    <ul className="list-none p-0 m-0">
                        {
                            groups?.result && groups?.result.map(group => {
                                return (<li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4" onClick={()=> history.push("/group/" + group?.id)}>
                                <Avatar src={group?.avatar} alt={group?.name} size={50}/>
                                <div>
                                    <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{group?.name + " " + group?.nameKorean}</span>

                                </div>
                                <div className="mt-2 md:mt-0 flex align-items-center">
                                    <span className="text-500 ml-3 font-medium">{group?.followers || 0 + " Followers"}</span>
                                </div>
                            </li>)
                            })
                        }
                        
                        
                    </ul>
                </div>
            </div>

          
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(HomePage, comparisonFn);
