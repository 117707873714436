import React  from 'react';
import { useAuthState } from '../use/AuthState';
import { Redirect, Route } from 'react-router-dom';

export const AuthenticatedRoute = ({ component: C, ...props }) => {
    const { isAuthenticated } = useAuthState()
    console.log(`AuthenticatedRoute: ${isAuthenticated}`)
    return (
        <Route
            {...props}
            render={routeProps =>
                isAuthenticated ? <C {...routeProps} /> : <Redirect to="/login" />
            }
        />
    )
}
