import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";

import { IdolsService } from "../../service/IdolsService";
import ModalIdolEditor from "../../customComponents/modals/ModalIdolEditor";
import ModalIdolCreator from "../../customComponents/modals/ModalIdolCreator";

const IdolsPage = () => {
    const idolsService = new IdolsService();
    const [displayConfirmation, setDisplayConfirmation] = useState(false);

    const history = useHistory();

    const toast = useRef();
    const timer = useRef(null);
    const [createIdol, setCreateIdol] = useState(false);
    const [idolIdToEdit, setIdolIdToEdit] = useState("");
    const [idolIdToDelete, setIdolIdToDelete] = useState("");
    const [searchIdolInput, setSearchIdolInput] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentRows, setCurrentRows] = useState(20);
    const [sortIdols, setSortIdols] = useState({ field: "creationDate", value: "desc" });
    const [filterIdols, setFilterIdols] = useState({
        displayName: { includes: searchIdolInput },
        realName: { includes: searchIdolInput },
    });
    const searchIdolsGraphQL = idolsService.useGetIdolsQuery({ filters: filterIdols, sort: sortIdols, rows: currentRows, page: currentPage });

    const [deleteIdolGraphQl, hookDeleteIdol] = idolsService.useDeleteIdolGraphQL();

    const idols = searchIdolsGraphQL?.data?.getIdols;

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            const filtersTmp = {
                displayName: { includes: searchIdolInput },
                or: {
                    realName: { includes: searchIdolInput }
                }
            };

            setFilterIdols(filtersTmp);
        }, 1000);
        return () => clearTimeout(timer.current);
    }, [searchIdolInput]);

    const confirmationDialogFooter = (line) => {
        return (
            <>
                <Button type="button" label="Non" icon="pi pi-times" onClick={() => setIdolIdToDelete("")} className="p-button-text" />
                <Button
                    type="button"
                    label="Oui"
                    icon="pi pi-check"
                    onClick={() => {
                        setIdolIdToDelete("");
                        deleteIdol(line);
                    }}
                    className="p-button-text"
                    autoFocus
                />
            </>
        );
    };

    const onPageInputChange = (event) => {
        console.log(event);
        setCurrentRows(event.rows);
        setCurrentPage(event.page + 1);
    };

    const bodyDisplayNameTemplate = (rowData) => {
        return <p>{rowData.displayName}</p>;
    };
    const bodyRealNameTemplate = (rowData) => {
        return <p>{rowData.realName}</p>;
    };
    const bodyDateOfBirthTemplate = (rowData) => {
        const date = new Date(rowData.dateOfBirth);
        return <p>{date.toLocaleDateString()}</p>;
    };
    const bodyGroupTemplate = (rowData) => {
        return <p>{rowData.group}</p>;
    };
    const bodyLikesTemplate = (rowData) => {
        return <p>{rowData.likes}</p>;
    };
    const bodySexeTemplate = (rowData) => {
        return <Image src={rowData.sexe === "M" ? "assets/male.png" : "assets/female.png"} width={40} />;
    };
    const bodyAvatarTemplate = (rowData) => {
        return <Image src={rowData.avatar} alt={rowData.displayName} width={150} preview />;
    };

    const bodyLastUpdateTemplate = (rowData) => {
        const date = new Date(rowData.creationDate);
        return <p>{date.toLocaleDateString()}</p>;
    };
    const bodyActionsTemplate = (rowData) => {
        return (
            <>
                <Button icon="pi pi-share-alt" className="p-button-rounded p-button-outlined p-button-success mr-2 mb-2" tooltip="Share" onClick={() => console.log("share")} />
                <Button icon="pi pi-plus" className="p-button-rounded p-button-outlined mr-2 mb-2" tooltip="See More" onClick={() => history.push("/idol/" + rowData?.id)} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-outlined mr-2 mb-2" tooltip="Edit" onClick={() => setIdolIdToEdit(rowData?.id)} />
                {idolIdToEdit === rowData.id && (
                    <ModalIdolEditor
                        visible={idolIdToEdit === rowData.id}
                        callback={(value) => {
                            if (value) {
                                searchIdolsGraphQL.refetch();
                            }
                            setIdolIdToEdit("");
                        }}
                        idol={rowData}
                    />
                )}
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined mr-2 mb-2" tooltip="Delete" onClick={() => setIdolIdToDelete(rowData?.id)} />
                {idolIdToDelete === rowData.id && (
                    <Dialog header="Confirmation" visible={idolIdToDelete === rowData.id} onHide={() => setIdolIdToDelete("")} style={{ width: "350px" }} modal footer={confirmationDialogFooter(rowData)}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            <span>{"Etes vous sur de vouloir supprimer : " + rowData?.displayName + "?"}</span>
                        </div>
                    </Dialog>
                )}
            </>
        );
    };

    const headerIdolsContainer = () => {
        return (
            <div className="p-fluid formgrid grid">
                <div className="field col-12 md:col-2">
                    <Button icon="pi pi-plus" style={{ marginRight: ".5em" }} label="Ajouter un idol" className="" onClick={() => setCreateIdol(!createIdol)}></Button>
                </div>
                <div className="col-12 mb-2 lg:col-6 lg:mb-0" />
                <div className="col-12 mb-2 lg:col-4 lg:mb-0">
                    <span className="p-input-icon-right">
                        <InputText type="text" placeholder="Search" onChange={(e) => setSearchIdolInput(e.target.value)} />
                        <i className="pi pi-search" />
                    </span>
                </div>
            </div>
        );
    };

    const deleteIdol = (idol) => {
        deleteIdolGraphQl({ variables: { id: idol.id } })
            .then((res) => {
                searchIdolsGraphQL.refetch();
                toast.current.show({ severity: "success", summary: "Successful", detail: "Idol deleted", life: 2000 });
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error.message, life: 3000 });
            });
    };

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Idols</h5>
                        {headerIdolsContainer()}
                        <DataTable
                            value={idols?.result}
                            totalRecords={idols?.total}
                            onPage={(p) => onPageInputChange(p)}
                            lazy
                            paginator
                            rows={currentRows}
                            rowsPerPageOptions={[10, 20, 50]}
                            first={(currentPage - 1) * currentRows}
                            scrollable
                            loading={searchIdolsGraphQL.loading}
                            scrollDirection="both"
                            className="mt-3"
                            sortField={sortIdols.field}
                            sortOrder={sortIdols.value === "asc" ? 1 : -1}
                            onSort={(e) => setSortIdols({ field: e.sortField, value: e.sortOrder === 1 ? "asc" : "desc" })}
                        >
                            <Column header="Image" style={{ width: "15%" }} body={(line) => bodyAvatarTemplate(line)} />
                            <Column field="displayName" sortable header="Display Name" style={{ width: "10%" }} body={(line) => bodyDisplayNameTemplate(line)} />
                            <Column field="realName" sortable header="Real Name" style={{ width: "15%" }} body={(line) => bodyRealNameTemplate(line)} />
                            <Column field="group" sortable header="Group" style={{ width: "10%" }} body={(line) => bodyGroupTemplate(line)} />
                            <Column field="sexe" sortable header="Sexe" style={{ width: "7%" }} body={(line) => bodySexeTemplate(line)} />
                            <Column field="dateOfBirth" sortable header="Date of Birth" style={{ width: "10%" }} body={(line) => bodyDateOfBirthTemplate(line)} />
                            <Column field="likes" sortable header="Likes" style={{ width: "8%" }} body={(line) => bodyLikesTemplate(line)} />
                            <Column field="creationDate" sortable header="Last Update" style={{ width: "10%" }} body={(line) => bodyLastUpdateTemplate(line)} />
                            <Column header="Actions" style={{ width: "15%" }} body={(line) => bodyActionsTemplate(line)} />
                        </DataTable>
                    </div>
                </div>
            </div>
            <ModalIdolCreator
                visible={createIdol}
                callback={(value) => {
                    searchIdolsGraphQL.refetch();
                    setCreateIdol(value);
                }}
            />

            <Toast ref={toast} />
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(IdolsPage, comparisonFn);
