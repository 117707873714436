import React, { useCallback, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../utils/firebase";
import classNames from "classnames";

const SignInPage = () => {
    const toast = useRef();

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();

        const { email, password } = e.target.elements;
        try {
            await signInWithEmailAndPassword(auth, email.value, password.value);
        } catch (e) {
            toast.current.show({ severity: "error", summary: "Error", detail: e.message, life: 3000 });
        }
    }, []);

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100rem" }}>
            <form onSubmit={handleSubmit} style={{ width: "500px" }}>
                <div className="card p-fluid">
                    <h5>Sign in</h5>
                    <div className="field">
                        <label htmlFor="email">Email</label>
                        <InputText id="email" type="text" />
                    </div>
                    <div className="field">
                        <label htmlFor="password">Password</label>
                        <InputText id="password" type="password" />
                    </div>

                    <Button label="Sign in" className="mr-2 mb-2"></Button>
                </div>
            </form>

            <Toast ref={toast} />
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SignInPage, comparisonFn);
