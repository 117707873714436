import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Toast } from "primereact/toast";

import { GalleryService } from "../../../service/GalleryService";

import ModalGallery from "../../modals/ModalGallery";

const ModalGalleryEditor = ({ visible, callback, group }) => {
    const galleryService = new GalleryService();
    const toast = useRef();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentRows, setCurrentRows] = useState(20);
    const [sortGalleries, setSortGalleries] = useState({ field: "creationDate", value: "desc" });
    const [filterGalleries, setFilterGalleries] = useState({modelType: {equalTo: "Group"}});
    const searchGalleriesGraphQL = galleryService.useGetGalleriesQuery({ filters: filterGalleries, sort: sortGalleries, rows: currentRows, page: currentPage });
    const [addGallerysGraphQL, hookAddGallery] = galleryService.useAddGalleryGraphQL();
    const [deleteGallerysGraphQL, hookDeleteGallery] = galleryService.useDeleteGalleryGraphQL();

    const galleries = searchGalleriesGraphQL?.data?.getGalleries;

    const [addNewImg, setAddNewImg] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [layout, setLayout] = useState("grid");
    const [newImg, setNewImg] = useState("");
    const [showGalleryModal, setShowGalleryModal] = useState(false);

    const deleteGallery = (gallery) => {
        deleteGallerysGraphQL({ variables: { id: gallery.id } })
            .then((res) => {
                searchGalleriesGraphQL.refetch();
                toast.current.show({ severity: "success", summary: "Successful", detail: "Gallery deleted", life: 2000 });
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error.message, life: 3000 });
            });
    };

    const onAddValidate = () => {
        const newGallery = {
            name: "",
            url: newImg,
            modelType: "Group",
            reference: group.id
        };
        addGallerysGraphQL({ variables: { gallery: newGallery } })
            .then((res) => {
                searchGalleriesGraphQL.refetch();
                setAddNewImg(false);
                toast.current.show({ severity: "success", summary: "Successful", detail: "Gallery added", life: 2000 });
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error.message, life: 3000 });
            });
    };
    const dataviewHeader = (
        <div className="grid grid-nogutter">
            <div className="col-3">
                <span className="p-input-icon-right">
                    <InputText type="text" placeholder="Search" onChange={(e) => setSearchInput(e.target.value)} />
                    <i className="pi pi-search" />
                </span>
            </div>
            <div className="col-6"></div>
            <div className="col-3" style={{ textAlign: "right" }}>
                <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
            </div>
        </div>
    );

    const itemTemplate = (data, layout) => {
        if (!data) {
            return;
        }
        if (layout === "list") {
            return dataviewListItem(data);
        } else if (layout === "grid") {
            return dataviewGridItem(data);
        }
    };
    const dataviewListItem = (data) => {
        return (
            <div className="col-12" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div className="flex flex-column md:flex-row align-items-center w-full">
                    <img src={data.url} style={{ maxWidth: "20%", maxHeight: "50px", margin: "30px" }} />
                    <p>{data.name}</p>
                </div>
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined mr-2 mb-2" tooltip="Delete" onClick={() => deleteGallery(data)} />
            </div>
        );
    };

    const dataviewGridItem = (data) => {
        return (
            <div className="col-12 md:col-2">
                <div className="card m-3 border-1 surface-border flex flex-column align-items-center">
                    <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined mr-2 mb-2" tooltip="Delete" onClick={() => deleteGallery(data)} />
                    <img src={data.url} style={{ maxWidth: "90%", maxHeight: "90%" }} />
                    <p>{data.name}</p>
                </div>
            </div>
        );
    };
    const galleryContainer = () => (
        <div className="grid mt-2">
            <div className="col-12">
                <DataView gutter value={galleries?.result} layout={layout} itemTemplate={itemTemplate} header={dataviewHeader} />
            </div>
        </div>
    );
    return (
        <>
            {visible && (
                <Sidebar visible={visible} onHide={() => callback(false)} baseZIndex={1000} fullScreen>
                    <h1 style={{ fontWeight: "normal" }}>Edition Gallery Group</h1>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card card-w-title">
                                <h5>Gallery</h5>
                                <div>
                                    <Button label="Ajouter une image" icon="pi pi-check" onClick={() => setAddNewImg(true)} loading={false} iconPos="right" className="mr-2 mb-2" />
                                </div>
                                {addNewImg && (
                                    <>
                                        <div>
                                            <Button label="Choisir" icon="pi pi-image" onClick={() => setShowGalleryModal(true)} loading={false} iconPos="right" className="mr-2 mb-2" />
                                            {!!newImg && <img src={newImg} style={{ maxHeight: "200px" }} />}
                                        </div>
                                        <div>
                                            <Button label="Annuler" icon="pi pi-times" onClick={() => callback(false)} iconPos="right" className="mr-2 mb-2 p-button-danger" />
                                            <Button label="Valider" icon="pi pi-check" onClick={() => onAddValidate()} loading={false} iconPos="right" className="mr-2 mb-2" />
                                        </div>
                                    </>
                                )}
                                {galleryContainer()}
                            </div>
                            <div>
                                <Button label="Terminer" onClick={() => callback(false)} iconPos="right" className="mr-2 mb-2" />
                            </div>
                        </div>
                    </div>
                </Sidebar>
            )}

            <ModalGallery
                visible={showGalleryModal}
                callback={(value) => {
                    if (!value) {
                        setShowGalleryModal(false);
                    } else {
                        setNewImg(value?.url);

                        setShowGalleryModal(false);
                    }
                }}
            />
            <Toast ref={toast} />
        </>
    );
};

export default ModalGalleryEditor;
