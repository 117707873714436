import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const app = initializeApp({
    apiKey: "AIzaSyATz6k6tgCaVLxY_HqyAwnf_sFktaCtyRQ",
    authDomain: "johayo-7a916.firebaseapp.com",
    projectId: "johayo-7a916",
    storageBucket: "johayo-7a916.appspot.com",
    messagingSenderId: "343373942198",
    appId: "1:343373942198:web:f8734545b4cf25f4660517",
    measurementId: "G-EDD60G9GFY",
});

export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);
