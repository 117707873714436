import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Tag } from "primereact/tag";

import { NotificationsService } from "../../service/NotificationsServices";
import ModalNotificationEditor from "../../customComponents/modals/ModalNotificationEditor";
import ModalNotificationCreator from "../../customComponents/modals/ModalNotificationCreator";

const fakeNotifs = [
    {
        image: "https://assets.s2mtechnologies.com/images/gallery/K-pop/Idols/Boys2/monsta%20x_minhyuk_thumbnail.png",
        title: "Best Dancer 2022",
        message: "Never miss your idols birthday",
        date: 1663108006861,
        status: "INPROGRESS",
        targetType: "IDOL",
        language: "en",
        target: "02qApUHC3g5sNp8fEeCl",
        creationDate: 1663108006861,
    },
    {
        image: "https://assets.s2mtechnologies.com/images/gallery/K-pop/Idols/Boys2/monsta%20x_minhyuk_thumbnail.png",
        title: "Best Dancer 2022",
        message: "Never miss your idols birthday",
        date: 1663108006861,
        status: "INPROGRESS",
        targetType: "IDOL",
        language: "en",
        target: "02qApUHC3g5sNp8fEeCl",
        creationDate: 1663108006861,
    },
    {
        image: "https://assets.s2mtechnologies.com/images/gallery/K-pop/Idols/Boys2/monsta%20x_minhyuk_thumbnail.png",
        title: "Best Dancer 2022",
        message: "Never miss your idols birthday",
        date: 1663108006861,
        status: "OVER",
        targetType: "IDOL",
        language: "en",
        target: "02qApUHC3g5sNp8fEeCl",
        creationDate: 1663108006861,
    },
    {
        image: "https://assets.s2mtechnologies.com/images/gallery/K-pop/Idols/Boys2/monsta%20x_minhyuk_thumbnail.png",
        title: "Best Dancer 2022",
        message: "Never miss your idols birthday",
        date: "",
        status: "INPROGRESS",
        targetType: "IDOL",
        language: "en",
        target: "02qApUHC3g5sNp8fEeCl",
        creationDate: 1663108006861,
    },
    {
        image: "https://assets.s2mtechnologies.com/images/gallery/K-pop/Idols/Boys2/monsta%20x_minhyuk_thumbnail.png",
        title: "Best Dancer 2022",
        message: "Never miss your idols birthday",
        date: "",
        status: "OVER",
        targetType: "IDOL",
        language: "en",
        target: "02qApUHC3g5sNp8fEeCl",
        creationDate: 1663108006861,
    },
    {
        image: "https://assets.s2mtechnologies.com/images/gallery/K-pop/Idols/Boys2/monsta%20x_minhyuk_thumbnail.png",
        title: "Best Dancer 2022",
        message: "Never miss your idols birthday",
        date: "",
        status: "INPROGRESS",
        targetType: "IDOL",
        language: "en",
        target: "02qApUHC3g5sNp8fEeCl",
        creationDate: 1663108006861,
    },
];

const NotificationsPage = () => {
    const notificationsService = new NotificationsService();
    const [displayConfirmation, setDisplayConfirmation] = useState(false);

    const history = useHistory();

    const toast = useRef();
    const timer = useRef(null);
    const [createNotification, setCreateNotification] = useState(false);
    const [notificationIdToEdit, setNotificationIdToEdit] = useState("");
    const [notificationIdToDelete, setNotificationIdToDelete] = useState("");
    const [searchNotificationInput, setSearchNotificationInput] = useState("");
    const [searchByDate, setSearchByDate] = useState("today");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentRows, setCurrentRows] = useState(20);
    const [sortNotifications, setSortNotifications] = useState({ field: "creationDate", value: "desc" });
    const [filterNotifications, setFilterNotifications] = useState([]);
    const searchNotificationsGraphQL = notificationsService.useSearchQueryGraphQL({ filters: filterNotifications, sort: sortNotifications, rows: currentRows, operator: "or", page: currentPage });

    const [deleteNotificationGraphQl, hookDeleteNotification] = notificationsService.useDeleteNotificationGraphQL();
    console.log("loading : " ,searchNotificationsGraphQL)

    const notifications = searchNotificationsGraphQL?.data?.searchNotifications;

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            const filtersTmp = [];
            filtersTmp.push({ field: "title", value: searchNotificationInput });
            filtersTmp.push({ field: "message", value: searchNotificationInput });
            setFilterNotifications(filtersTmp);
        }, 1000);
        return () => clearTimeout(timer.current);
    }, [searchNotificationInput]);

    useEffect(() => {}, [sortNotifications]);

    const confirmationDialogFooter = (line) => {
        return (
            <>
                <Button type="button" label="Non" icon="pi pi-times" onClick={() => setNotificationIdToDelete("")} className="p-button-text" />
                <Button
                    type="button"
                    label="Oui"
                    icon="pi pi-check"
                    onClick={() => {
                        setNotificationIdToDelete("");
                        deleteNotification(line);
                    }}
                    className="p-button-text"
                    autoFocus
                />
            </>
        );
    };

    const onPageInputChange = (event) => {
        console.log(event);
        setCurrentRows(event.rows);
        setCurrentPage(event.page + 1);
    };

    const onSendAgain = (notification) =>{
        if(notification.status !== "OVER"){
            toast.current.show({ severity: "error", summary: "Impossible", detail: "You can't send again a notification not finished", life: 3000 });
        }else{
            if(notification.date.toString().length === 13){
                toast.current.show({ severity: "error", summary: "Impossible", detail: "Only instant notification can be sent again. Create a new one with différent dates", life: 3000 });
            }else{
                //TODO SEND NOTIFICATION NOW
            }

        }

    }
   
    const bodyTitleTemplate = (rowData) => {
        return <p>{rowData.title}</p>;
    };
    const bodyMessageTemplate = (rowData) => {
        return <p>{rowData.message}</p>;
    };
    const bodyDateTemplate = (rowData) => {
        if(rowData.date.toString().length === 13){
            const date = new Date(rowData.date);
            return <p>{date.toLocaleDateString()}</p>;
        }else{
            return <Tag className="mr-2" severity="info" value={"Sent instantly"} rounded />;
        }
       
    };
    const bodyTargetTypeTemplate = (rowData) => {
        return <p>{rowData.targetType}</p>;
    };
    const bodyTargetTemplate = (rowData) => {
        return <p>{rowData.target}</p>;
    };
    const bodyImageTemplate = (rowData) => {
        return <Image src={rowData.image} alt={rowData.title} width={150} preview />;
    };
    const bodyLanguageTemplate = (rowData) => {
        return <p>{rowData.language}</p>;
    };

    const bodyStatusTemplate = (rowData) => {
        if (rowData.status === "OVER") return <Tag className="mr-2" severity="primary" value="Envoyé" rounded />;
        if (rowData.status === "NOTSTARTED") return <Tag className="mr-2" severity="primary" value="Non commencé" rounded />;
        if (rowData.status === "INPROGRESS") return <Tag className="mr-2" severity="primary" value="En Cours" rounded />;
        return <Tag className="mr-2" severity="info" value={rowData.status} rounded />;
    };

    const bodyLastUpdateTemplate = (rowData) => {
        const date = new Date(rowData.creationDate);
        return <p>{date.toLocaleDateString()}</p>;
    };
    const bodyActionsTemplate = (rowData) => {
        return (
            <>
                <Button icon="pi pi-send" className="p-button-rounded p-button-outlined p-button-success mr-2 mb-2" tooltip="Send Again" onClick={() => onSendAgain(rowData)} />
                <Button icon="pi pi-plus" className="p-button-rounded p-button-outlined  p-button-secondary mr-2 mb-2" tooltip="See More" onClick={() => history.push("/notification/" + rowData.id)} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-outlined mr-2 mb-2" tooltip="Edit" onClick={() => setNotificationIdToEdit(rowData?.id)} />
                {notificationIdToEdit === rowData.id && <ModalNotificationEditor
                    visible={notificationIdToEdit === rowData.id}
                    callback={() => {
                        searchNotificationsGraphQL.refetch();
                        setNotificationIdToEdit("");
                    }}
                    notification={rowData}
                />}
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined mr-2 mb-2" tooltip="Delete" onClick={() => setNotificationIdToDelete(rowData?.id)} />
                {notificationIdToDelete === rowData.id && (
                    <Dialog header="Confirmation" visible={notificationIdToDelete === rowData.id} onHide={() => setNotificationIdToDelete("")} style={{ width: "350px" }} modal footer={confirmationDialogFooter(rowData)}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            <span>{"Etes vous sur de vouloir supprimer : " + rowData?.displayName + "?"}</span>
                        </div>
                    </Dialog>
                )}
            </>
        );
    };

    const headerNotificationsContainer = () => {
        return (
            <div className="p-fluid formgrid grid">
                <div className="field col-12 md:col-4">
                    <Button icon="pi pi-plus" style={{ marginRight: ".5em" }} label="Ajouter une notification" className="" onClick={() => setCreateNotification(!createNotification)}></Button>
                </div>
                <div className="col-12 mb-2 lg:col-4 lg:mb-0" />
                <div className="col-12 mb-2 lg:col-4 lg:mb-0">
                    <span className="p-input-icon-right">
                        <InputText type="text" placeholder="Search" onChange={(e) => setSearchNotificationInput(e.target.value)} />
                        <i className="pi pi-search" />
                    </span>
                </div>
            </div>
        );
    };

  

    const deleteNotification = (notification) => {
        deleteNotificationGraphQl({ variables: { id: notification.id } })
            .then((res) => {
                searchNotificationsGraphQL.refetch();
                toast.current.show({ severity: "success", summary: "Successful", detail: "Notification deleted", life: 2000 });
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error.message, life: 3000 });
            });
    };

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Notifications</h5>
                        {headerNotificationsContainer()}
                        <DataTable
                            value={fakeNotifs}
                            totalRecords={fakeNotifs?.length}
                            onPage={(p) => onPageInputChange(p)}
                            lazy
                            paginator
                            rows={currentRows}
                            rowsPerPageOptions={[10, 20, 50]}
                            first={(currentPage - 1) * currentRows}
                            scrollable
                            loading={searchNotificationsGraphQL.loading}
                            scrollDirection="both"
                            className="mt-3"
                            sortField={sortNotifications.field}
                            sortOrder={sortNotifications.value === "asc" ? 1 : -1}
                            onSort={(e) => setSortNotifications({ field: e.sortField, value: e.sortOrder === 1 ? "asc" : "desc" })}
                        >
                            <Column field="image" header="Image" style={{ width: "10%" }} body={(line) => bodyImageTemplate(line)} />
                            <Column field="title" sortable header="Title" style={{ width: "10%" }} body={(line) => bodyTitleTemplate(line)} />
                            <Column field="message" sortable header="Message" style={{ width: "21%" }} body={(line) => bodyMessageTemplate(line)} />
                            <Column field="targetType" sortable header="Target Type" style={{ width: "10%" }} body={(line) => bodyTargetTypeTemplate(line)} />
                            <Column field="target" sortable header="Target" style={{ width: "7%" }} body={(line) => bodyTargetTemplate(line)} />
                            <Column field="language" sortable header="Language" style={{ width: "7%" }} body={(line) => bodyLanguageTemplate(line)} />
                            <Column field="date" sortable header="Date" style={{ width: "7%" }} body={(line) => bodyDateTemplate(line)} />
                            <Column field="status" sortable header="Status" style={{ width: "6%" }} body={(line) => bodyStatusTemplate(line)} />
                            <Column field="creationDate" sortable header="Last Update" style={{ width: "7%" }} body={(line) => bodyLastUpdateTemplate(line)} />
                            <Column header="Actions" style={{ width: "15%" }} body={(line) => bodyActionsTemplate(line)} />
                        </DataTable>
                    </div>
                </div>
            </div>
            <ModalNotificationCreator
                visible={createNotification}
                callback={(value) => {
                    searchNotificationsGraphQL.refetch();
                    setCreateNotification(value);
                }}
            />

            <Toast ref={toast} />
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(NotificationsPage, comparisonFn);
