import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import { Accordion, AccordionTab } from "primereact/accordion";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Rating } from "primereact/rating";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";

import { IdolsService } from "../../service/IdolsService";
import { GalleryService } from "../../service/GalleryService";

import ModalDescriptionEditor from "../../customComponents/modals/IdolCategories/ModalDescriptionEditor";
import ModalGalleryEditor from "../../customComponents/modals/IdolCategories/ModalGalleryEditor";
import ModalIdolEditor from "../../customComponents/modals/ModalIdolEditor";
import { DetailContainer } from "../../customComponents/components/Detail";
import { Avatar } from "../../customComponents/components/Avatar";

const IdolPage = () => {
    const params = useParams();
    const idolsService = new IdolsService();
    const galleryService = new GalleryService();
    const useGetIdol = idolsService.useGetIdolByIdQuery(params.id);
    const idol = useGetIdol?.data?.getIdol;
    const toast = useRef();

    const [updateIdolGraphQL, hookAddIdol] = idolsService.useUpdateIdolGraphQL();

    const [currentPage, setCurrentPage] = useState(1);
    const [currentRows, setCurrentRows] = useState(20);
    const [sortGalleries, setSortGalleries] = useState({ field: "creationDate", value: "desc" });
    const [filterGalleries, setFilterGalleries] = useState({ modelType: { equalTo: "Idol" } });
    const searchGalleriesGraphQL = galleryService.useGetGalleriesQuery({ filters: filterGalleries, sort: sortGalleries, rows: currentRows, page: currentPage });
    const galleries = searchGalleriesGraphQL?.data?.getGalleries;

    const [createCategory, setCreateCategory] = useState(false);
    const [newCategory, setNewCategory] = useState("");
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [editIdol, setEditIdol] = useState(false);

    const [editDescription, setEditDescription] = useState(false);
    const [editGallery, setEditGallery] = useState(false);
    const [editLadder, setEditLadder] = useState(false);

    const [searchInput, setSearchInput] = useState("");
    const [layout, setLayout] = useState("grid");

    const [sortReviews, setSortReviews] = useState({ field: "creationDate", value: "asc" });
    const [sortReviewsKey, setSortReviewsKey] = useState("creationDate");

    const [currentReviewsPage, setCurrentReviewsPage] = useState(1);
    const [currentReviewsRows, setCurrentReviewsRows] = useState(5);

    const dataviewHeader = (
        <div className="grid grid-nogutter">
            <div className="col-3">
                <span className="p-input-icon-right">
                    <InputText type="text" placeholder="Search" onChange={(e) => setSearchInput(e.target.value)} />
                    <i className="pi pi-search" />
                </span>
            </div>
            <div className="col-6"></div>
            <div className="col-3" style={{ textAlign: "right" }}>
                <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
            </div>
        </div>
    );

    const onSortReviewChange = (event) => {
        const value = event.value;

        if (value.indexOf("!") === 0) {
            setSortReviews({ field: value.substring(1, value.length), value: "desc" });
        } else {
            setSortReviews({ field: value, value: "asc" });
        }
        setSortReviewsKey(value);
    };
    useEffect(() => {
        const catOptions = [];
        if (!idol?.description) {
            catOptions.push({
                label: "Description",
                value: "description",
            });
        }

        setCategoryOptions(catOptions);
    }, [idol]);

    const onActiveOptionChange = (key) => {
        const newCategories = idol?.activeCategories ? [...idol?.activeCategories] : [];
        console.log("newCategories: ", newCategories);
        if (newCategories.includes(key)) {
            newCategories.splice(newCategories.indexOf(key), 1);
        } else {
            console.log("key: ", key);
            newCategories.push(key);
        }
        console.log("newCategories A: ", newCategories);
        updateIdolGraphQL({ variables: { id: idol.id, idol: { activeCategories: newCategories } } })
            .then((res) => {
                useGetIdol.refetch();
                toast.current.show({ severity: "success", summary: "Successful", detail: "Category Updated", life: 2000 });
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error.message, life: 3000 });
            });
    };
    const renderDescriptionHeader = () => {
        return (
            <div style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "space-between" }}>
                <p style={{ margin: "0px 20px" }}>Description</p>
                <div style={{ display: "flex", alignContent: "center", alignItems: "center" }}>
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-outlined mr-2 mb-2"
                        tooltip="Edit"
                        onClick={(e) => {
                            setEditDescription(true);
                        }}
                    />
                    <div style={{ marginRight: "20px" }} onClick={(e) => e.stopPropagation()}>
                        <InputSwitch checked={idol?.activeCategories?.includes("description")} onChange={() => onActiveOptionChange("description")} />
                    </div>
                </div>
            </div>
        );
    };
    const renderGalleryHeader = () => {
        return (
            <div style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "space-between" }}>
                <p style={{ margin: "0px 20px" }}>Gallery</p>
                <div style={{ display: "flex", alignContent: "center", alignItems: "center" }}>
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-outlined mr-2 mb-2"
                        tooltip="Edit"
                        onClick={(e) => {
                            setEditGallery(true);
                        }}
                    />
                    <div style={{ marginRight: "20px" }} onClick={(e) => e.stopPropagation()}>
                        <InputSwitch checked={idol?.activeCategories?.includes("gallery")} onChange={() => onActiveOptionChange("gallery")} />
                    </div>
                </div>
            </div>
        );
    };
    const renderReviewHeader = () => {
        return (
            <div style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "space-between" }}>
                <p style={{ margin: "0px 20px" }}>Reviews</p>
                <div style={{ display: "flex", alignContent: "center", alignItems: "center" }}>
                    <div style={{ marginRight: "20px" }} onClick={(e) => e.stopPropagation()}>
                        <InputSwitch checked={idol?.activeCategories?.includes("reviews")} onChange={() => onActiveOptionChange("reviews")} />
                    </div>
                </div>
            </div>
        );
    };
    const renderLadderHeader = () => {
        return (
            <div style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "space-between" }}>
                <p style={{ margin: "0px 20px" }}>Ladder</p>
                <div style={{ display: "flex", alignContent: "center", alignItems: "center" }}>
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-outlined mr-2 mb-2"
                        tooltip="Edit"
                        onClick={(e) => {
                            setEditLadder(true);
                        }}
                    />
                    <div style={{ marginRight: "20px" }} onClick={(e) => e.stopPropagation()}>
                        <InputSwitch checked={idol?.activeCategories?.includes("ladder")} onChange={() => onActiveOptionChange("ladder")} />
                    </div>
                </div>
            </div>
        );
    };
    const createCategoryForm = () => {
        return (
            <>
                <div className="field col-12 md:col-4">
                    <label htmlFor="language">Categories</label>
                    <Dropdown
                        id="language"
                        options={categoryOptions}
                        value={newCategory}
                        onChange={(e) => {
                            setNewCategory(e.value);
                        }}
                        className={!!newCategory ? "ml-2" : "p-invalid ml-2"}
                    />
                </div>
                <div>
                    <Button label="Annuler" icon="pi pi-times" onClick={() => setCreateCategory(false)} iconPos="right" className="mr-2 mb-2 p-button-danger" />
                    <Button label="Valider" icon="pi pi-check" onClick={() => handleCreateNewCategory()} loading={false} iconPos="right" className="mr-2 mb-2" />
                </div>
            </>
        );
    };
    const handleCreateNewCategory = () => {
        updateIdolGraphQL({ variables: { id: idol.id, idol: { [newCategory]: "new" } } })
            .then((res) => {
                useGetIdol.refetch();
                toast.current.show({ severity: "success", summary: "Successful", detail: "Category added", life: 2000 });
                setCreateCategory(false);
            })
            .catch((error) => {
                setCreateCategory(false);
                toast.current.show({ severity: "error", summary: "Error", detail: error.message, life: 3000 });
            });
    };
    const renderDescriptionBody = () => {
        return (
            <>
                {idol?.description && <div dangerouslySetInnerHTML={{ __html: idol?.description }} />}
                {editDescription && (
                    <ModalDescriptionEditor
                        visible={editDescription}
                        callback={() => {
                            useGetIdol.refetch();
                            setEditDescription(false);
                        }}
                        idol={idol}
                    />
                )}
            </>
        );
    };
    const renderGalleryBody = () => {
        const itemTemplate = (data, layout) => {
            if (!data) {
                return;
            }
            if (layout === "list") {
                return dataviewListItem(data);
            } else if (layout === "grid") {
                return dataviewGridItem(data);
            }
        };
        const dataviewListItem = (data) => {
            const selected = false;
            return (
                <div className="col-12" onClick={() => console.log("doc")}>
                    <div className="flex flex-column md:flex-row align-items-center w-full" style={{ cursor: "pointer", border: selected ? "2px solid green" : "0px" }}>
                        <img src={data.url} style={{ maxWidth: "20%", maxHeight: "50px", margin: "30px" }} />
                        <p>{data.name}</p>
                    </div>
                </div>
            );
        };

        const dataviewGridItem = (data) => {
            const selected = false;
            return (
                <div className="col-12 md:col-2" onClick={() => console.log("doc")}>
                    <div className="card m-3 border-1 surface-border flex flex-column align-items-center" style={{ padding: "0", cursor: "pointer", border: selected ? "2px solid green" : "0px" }}>
                        <img src={data.url} style={{ maxWidth: "90%", maxHeight: "90%" }} />
                        <p>{data.name}</p>
                    </div>
                </div>
            );
        };
        return (
            <>
                <DataView gutter value={galleries?.result} layout={layout} itemTemplate={itemTemplate} header={dataviewHeader} />
                {editGallery && (
                    <ModalGalleryEditor
                        visible={editGallery}
                        callback={() => {
                            searchGalleriesGraphQL.refetch();
                            setEditGallery(false);
                        }}
                        idol={idol}
                    />
                )}
            </>
        );
    };
    const renderReviewBody = () => {

        const sortOptions = [
            { label: "Creation Date Asc", value: "creationDate" },
            { label: "Creation Date Desc", value: "!creationDate" },
            { label: "Rate Asc", value: "rate" },
            { label: "Rate Desc", value: "!rate" },
        ];
        
        const listItem = (line) => {
            return (
                <div className="col-12">
                    <div className="flex flex-column md:flex-row align-items-center p-3 w-full">
                        <Avatar src={`${line?.user?.avatar}`} alt={line?.user?.username} size={50} />
                        <div className="flex-1 text-center md:text-left">
                            <div className="font-bold text-xl">{line?.user?.username}</div>
                            <div className="mb-3">{line?.message}</div>
                        </div>
                        <div className="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0">
                            <span className="text-2xl font-semibold mb-2 align-self-center md:align-self-end">
                                <Rating value={line?.rate} readOnly cancel={false} />
                            </span>
                        </div>
                    </div>
                </div>
            );
        };
        return (
            <div>
                <div style={{ textAlign: "left", marginRight: "10px" }}>
                    <Dropdown value={sortReviewsKey} options={sortOptions} optionLabel="label" placeholder="Sort By Creation Date" onChange={(e) => onSortReviewChange(e)} />
                </div>
                <DataView
                    value={reviews}
                    paginator
                    rows={currentReviewsRows}
                    sortOrder={sortReviews.value === "asc" ? 1 : -1}
                    sortField={sortReviews.field}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    first={(currentReviewsPage - 1) * currentReviewsRows}
                    onSort={(e) => setSortReviews({ field: e.sortField, value: e.sortOrder === 1 ? "asc" : "desc" })}
                    itemTemplate={(line) => listItem(line)}
                />
            </div>
        );
    };
    const renderLadderBody = () => {
        return (
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
        );
    };

    const details = [
        {
            label: "Real Name",
            value: idol?.realName,
        },
        {
            label: "Group",
            value: idol?.group,
        },
        {
            label: "likes",
            value: idol?.likes,
        },
        {
            label: "Date of Birth",
            value: new Date(idol?.dateOfBirth).toLocaleDateString("fr"),
        },
        {
            label: "Last Update",
            value: new Date(idol?.updateDate).toLocaleDateString("fr"),
        },
        {
            label: "Creation Date",
            value: new Date(idol?.creationDate).toLocaleDateString("fr"),
        },
    ];

    const reviews = [
        {
            creationDate: 1,
            message: "She is so good everything. She diserve the best ! ",
            rate: 1,
            user: {
                avatar: "https://assets.s2mtechnologies.com/images/gallery/K-pop/Idols/Girls2/mimiirose-seo%20yun%20ju_thumbnail.png",
                username: "Seo Yun's fan 1",
            },
        },
        {
            creationDate: 13,
            message: "She is so good everything. She diserve the best ! ",
            rate: 2.5,
            user: {
                avatar: "https://assets.s2mtechnologies.com/images/gallery/K-pop/Idols/Girls2/mimiirose-seo%20yun%20ju_thumbnail.png",
                username: "Seo Yun's fan 2",
            },
        },
        {
            creationDate: 94329,
            message: "She is so good everything. She diserve the best ! ",
            rate: 3,
            user: {
                avatar: "https://assets.s2mtechnologies.com/images/gallery/K-pop/Idols/Girls2/mimiirose-seo%20yun%20ju_thumbnail.png",
                username: "Seo Yun's fan 3",
            },
        },
        {
            creationDate: 54,
            message: "She is so good everything. She diserve the best ! ",
            rate: 3.5,
            user: {
                avatar: "https://assets.s2mtechnologies.com/images/gallery/K-pop/Idols/Girls2/mimiirose-seo%20yun%20ju_thumbnail.png",
                username: "Seo Yun's fan 4",
            },
        },
        {
            creationDate: 11,
            message: "She is so good everything. She diserve the best ! ",
            rate: 4,
            user: {
                avatar: "https://assets.s2mtechnologies.com/images/gallery/K-pop/Idols/Girls2/mimiirose-seo%20yun%20ju_thumbnail.png",
                username: "Seo Yun's fan 5",
            },
        },
        {
            creationDate: 12311,
            message: "She is so good everything. She diserve the best ! ",
            rate: 4.5,
            user: {
                avatar: "https://assets.s2mtechnologies.com/images/gallery/K-pop/Idols/Girls2/mimiirose-seo%20yun%20ju_thumbnail.png",
                username: "Seo Yun's fan 6",
            },
        },
        {
            creationDate: 131,
            message: "She is so good everything. She diserve the best ! ",
            rate: 4.3,
            user: {
                avatar: "https://assets.s2mtechnologies.com/images/gallery/K-pop/Idols/Girls2/mimiirose-seo%20yun%20ju_thumbnail.png",
                username: "Seo Yun's fan 7",
            },
        },
    ];

    console.log("idol: ", idol)

    return (
        <div className="grid">
            {!!idol && (
                <>
                    <div className="col-12">
                        <div className="card">
                            {idol?.banner && <img src={idol?.banner} alt={idol?.displayName} style={{ maxWidth: "100%" }} />}
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Avatar src={idol?.avatar} alt={idol?.displayName} size={200} />
                                <h5>{idol?.displayName}</h5>
                                <h5>{idol?.shortDescription}</h5>
                                <p style={{ margin: "5px", padding: "0px" }}>{idol?.followers}</p>
                            </div>
                            <div className="field col-12 md:col-2">
                                <Button icon="pi pi-pencil" style={{ marginRight: ".5em" }} label="Modifier" className="" onClick={() => setEditIdol(!editIdol)} />
                            </div>
                            <DetailContainer data={details} />
                        </div>
                    </div>
                    <div className="col-12 md:col-12">
                        <div className="card">
                            <div style={{ width: "100%" }}>
                                <Button icon="pi pi-plus" style={{ marginRight: ".5em", marginBottom: "10px" }} label="Ajouter une catégorie" onClick={() => setCreateCategory(!createCategory)}></Button>
                                {createCategory && createCategoryForm()}
                            </div>
                            <Accordion activeIndex={0}>
                                {!!idol?.description && <AccordionTab header={renderDescriptionHeader()}>{renderDescriptionBody()}</AccordionTab>}
                                {<AccordionTab header={renderGalleryHeader()}>{renderGalleryBody()}</AccordionTab>}
                                {<AccordionTab header={renderReviewHeader()}>{renderReviewBody()}</AccordionTab>}
                                {<AccordionTab header={renderLadderHeader()}>{renderLadderBody()}</AccordionTab>}
                            </Accordion>
                        </div>
                    </div>
                    {editIdol && (
                        <ModalIdolEditor
                            visible={editIdol}
                            callback={(value) => {
                                if (value) {
                                    useGetIdol.refetch();
                                }
                                setEditIdol(false);
                            }}
                            idol={idol}
                        />
                    )}
                </>
            )}
            <Toast ref={toast} />
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(IdolPage, comparisonFn);
