import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { InputMask } from "primereact/inputmask";
import moment from "moment";

import { GroupsService } from "../../service/GroupsService";

import ModalGallery from "../modals/ModalGallery";

const ModalGroupEditor = ({ visible, callback, group }) => {
    const groupsService = new GroupsService();
    const toast = useRef();
    const [updateGroupGraphQL, hookUpdateGroup] = groupsService.useUpdateGroupGraphQL();

    const [name, setName] = useState(group?.name || "");
    const [nameKorean, setNameKorean] = useState(group?.nameKorean || "");
    const [debut, setDebut] = useState(moment(group?.debut).format("YYYY/MM/DD") || "");
    const [avatar, setAvatar] = useState(group?.avatar || "");
    const [banner, setBanner] = useState(group?.banner || "");
    const [fandom, setFandom] = useState(group?.fandom || "");
    const [agency, setAgency] = useState(group?.agency || "");
    const [shortDescription, setShortDescription] = useState(group?.shortDescription || "");

    const [showGalleryModalAvatar, setShowGalleryModalAvatar] = useState(false);
    const [showGalleryModalBanner, setShowGalleryModalBanner] = useState(false);

    const onEditGroup = () => {
        const debutDate = new Date(debut);
        const new_group = {
            avatar: avatar,
            banner: banner,
            name: name,
            nameKorean: nameKorean,
            fandom: fandom,
            agency: agency,
            shortDescription: shortDescription,
            debut: debutDate.getTime(),
        };
        updateGroupGraphQL({ variables: { id: group.id, group: new_group } })
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Successful", detail: "group Updated", life: 2000 });
                callback(true);
            })
            .catch((error) => {
                callback(false);
                toast.current.show({ severity: "error", summary: "Error", detail: error.message, life: 3000 });
            });
    };
    return (
        <>
            {visible && (
                <Sidebar visible={visible} baseZIndex={1000} fullScreen>
                    <h1 style={{ fontWeight: "normal" }}>Edition d'un group</h1>
                    <div className="col-12">
                        <div className="card">
                            <div className="grid p-fluid mt-3">
                                <div className="field col-12 md:col-6">
                                    <div className="field">
                                        <label htmlFor="name">Name</label>
                                        <InputText type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} className={!!name ? "" : "p-invalid"} />
                                    </div>
                                </div>
                                <div className="field col-12 md:col-6">
                                    <div className="field">
                                        <label htmlFor="nameKorean">Name Korean</label>
                                        <InputText type="text" id="nameKorean" value={nameKorean} onChange={(e) => setNameKorean(e.target.value)} className={!!name ? "" : "p-invalid"} />
                                    </div>
                                </div>
                                <div className="field col-12 md:col-6">
                                    <div className="field">
                                        <label htmlFor="fandom">Fandom</label>
                                        <InputText type="text" id="fandom" value={fandom} onChange={(e) => setFandom(e.target.value)} className={!!name ? "" : "p-invalid"} />
                                    </div>
                                </div>
                                <div className="field col-12 md:col-6">
                                    <div className="field">
                                        <label htmlFor="shortDescription">Small description</label>
                                        <InputText type="text" id="shortDescription" value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} className={!!shortDescription ? "" : "p-invalid"} />
                                    </div>
                                </div>
                                <div className="field col-12 md:col-6">
                                    <div className="field">
                                        <label htmlFor="agency">Agency</label>
                                        <InputText type="text" id="agency" value={agency} onChange={(e) => setAgency(e.target.value)} className={!!name ? "" : "p-invalid"} />
                                    </div>
                                </div>

                                <div className="field col-12 md:col-6">
                                    <label htmlFor="debut">Debut</label>
                                    <InputMask id="debut" mask="9999/99/99" value={debut} onChange={(e) => setDebut(e.value)} className={!!debut ? "" : "p-invalid"} />
                                </div>
                                <div>
                                    <label htmlFor="avatar">Avatar</label>
                                    <Button label="Choisir" icon="pi pi-image" onClick={() => setShowGalleryModalAvatar(true)} loading={false} iconPos="right" className="mr-2 mb-2" />
                                    {!!avatar && <img src={avatar} style={{ maxHeight: "200px" }} />}
                                </div>
                                <div>
                                    <label htmlFor="banner">Banner</label>
                                    <Button label="Choisir" icon="pi pi-image" onClick={() => setShowGalleryModalBanner(true)} loading={false} iconPos="right" className="mr-2 mb-2" />
                                    {!!banner && <img src={banner} style={{ maxHeight: "200px" }} />}
                                </div>
                            </div>
                            <div>
                                <Button label="Annuler" icon="pi pi-times" onClick={() => callback(false)} iconPos="right" className="mr-2 mb-2 p-button-danger" />
                                <Button label="Confirmer" icon="pi pi-check" onClick={() => onEditGroup()} loading={false} iconPos="right" className="mr-2 mb-2" />
                            </div>
                        </div>
                    </div>
                </Sidebar>
            )}
            <ModalGallery
                visible={showGalleryModalAvatar}
                callback={(value) => {
                    if (!value) {
                        setShowGalleryModalAvatar(false);
                    } else {
                        setAvatar(value?.url);
                        setShowGalleryModalAvatar(false);
                    }
                }}
            />
            <ModalGallery
                visible={showGalleryModalBanner}
                callback={(value) => {
                    if (!value) {
                        setShowGalleryModalBanner(false);
                    } else {
                        setBanner(value?.url);
                        setShowGalleryModalBanner(false);
                    }
                }}
            />
            <Toast ref={toast} />
        </>
    );
};

export default ModalGroupEditor;
