import { gql, useQuery, useMutation } from "@apollo/client";

const GET_IDOL = gql`
    query GetIdol($id: ID!) {
        getIdol(id: $id) {
            id
            avatar
            birthDay
            birthMonth
            birthWeek
            birthYear
            creationDate
            dateOfBirth
            likes
            displayName
            group
            realName
            sexe
            description
            activeCategories
            shortDescription
            followers
        }
    }
`;
const ADD_IDOL = gql`
    mutation addIdol($idol: IdolInput) {
        addIdol(idol: $idol) {
            id
            avatar
            birthDay
            birthMonth
            birthWeek
            birthYear
            creationDate
            dateOfBirth
            likes
            displayName
            group
            realName
            sexe
            shortDescription
            followers
        }
    }
`;
const UPDATE_IDOL = gql`
    mutation updateIdol($id: ID!, $idol: IdolInput) {
        updateIdol(id: $id, idol: $idol) {
            id
            avatar
            birthDay
            birthMonth
            birthWeek
            birthYear
            creationDate
            dateOfBirth
            likes
            displayName
            group
            realName
            sexe
            shortDescription
            followers
            
        }
    }
`;

const DELETE_IDOL = gql`
    mutation deleteIdol($id: ID!) {
        deleteIdol(id: $id)
    }
`;

const SEARCH_IDOLS = gql`
    query GetIdols($filters: IdolFilter, $page: Int, $rows: Int, $sort: Sort) {
        getIdols(filters: $filters, page: $page, rows: $rows, sort: $sort) {
            total
            result {
                id
                avatar
                birthDay
                birthMonth
                birthWeek
                birthYear
                creationDate
                dateOfBirth
                likes
                displayName
                group
                realName
                sexe
                shortDescription
                followers
            }
        }
    }
`;

export class IdolsService {
    useGetIdolsQuery = ({ filters, page, rows, sort }) => {
        return useQuery(SEARCH_IDOLS, {
            variables: {
                filters,
                page,
                rows,
                sort
            },
        });
    };
    useGetIdolByIdQuery = (id) => {
        return useQuery(GET_IDOL, {
            variables: {
                id,
            },
        });
    };
    useDeleteIdolGraphQL = (id) => {
        return useMutation(DELETE_IDOL);
    };
    useAddIdolGraphQL = () => {
        return useMutation(ADD_IDOL);
    };

    useUpdateIdolGraphQL = () => {
        return useMutation(UPDATE_IDOL);
    };
}
