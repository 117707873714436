import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { DataView } from "primereact/dataview";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { Avatar } from "primereact/avatar";

import { GroupsService } from "../../../service/GroupsService";
import { IdolsService } from "../../../service/IdolsService";

const ModalMembersEditor = ({ visible, callback, group }) => {
    const groupsService = new GroupsService();
    const idolsService = new IdolsService();
    const toast = useRef();
    const timer = useRef(null);
    const [updateGroupGraphQL, hookUpdateGroup] = groupsService.useUpdateGroupGraphQL();

    const [filterIdols, setFilterIdols] = useState({});
    const [searchIdolInput, setSearchIdolInput] = useState("");
    const searchIdolsGraphQL = idolsService.useGetIdolsQuery({ filters: filterIdols, rows: 200 });

    const idols = searchIdolsGraphQL?.data?.getIdols;

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            const filtersTmp = {
                displayName: { includes: searchIdolInput },
                or: {
                    realName: { includes: searchIdolInput },
                },
            };

            setFilterIdols(filtersTmp);
        }, 1000);
        return () => clearTimeout(timer.current);
    }, [searchIdolInput]);

    const [members, setMembers] = useState(group?.members || []);

    const onValidate = () => {
        const newObj = {
            members: members.map((el) => el.id),
        };
        updateGroupGraphQL({ variables: { id: group.id, group: newObj } })
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Successful", detail: "Group Updated", life: 2000 });
                callback(false);
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error.message, life: 3000 });
            });
    };

    const listItemInSelect = (line) => {
        return (
            <div className="col-12">
                <div className="flex align-items-center ">
                    <Avatar image={`${line.avatar}`} alt={line?.displayName} />

                    <p style={{ paddingLeft: "5px" }}>{line?.displayName}</p>
                </div>
            </div>
        );
    };

    const listItem = (line) => {
        return (
            <div className="col-12">
                <div className="flex flex-column md:flex-row align-items-center p-3 w-full">
                    <img src={`${line.avatar}`} alt={line?.displayName} className="my-4 md:my-0 w-9 md:w-10rem shadow-2 mr-5" />
                    <div className="flex-1 text-center md:text-left">
                        <div className="font-bold text-2xl">{line?.displayName}</div>
                    </div>
                    <div className="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0">
                        <span className="text-2xl font-semibold mb-2 align-self-center md:align-self-end">{line?.followers || 0}</span>
                        <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined mr-2 mb-2" tooltip="Delete" onClick={() => unSelect(line)} />
                    </div>
                </div>
            </div>
        );
    };

    const unSelect = (item) => {
        const el = members.filter((el) => el.id === item.id)[0];
        const newMembers = [...members];
        newMembers.splice(newMembers.indexOf(el), 1);
        setMembers(newMembers);
    };

    return (
        <>
            {visible && (
                <Sidebar visible={visible} onHide={() => callback(false)} baseZIndex={1000} fullScreen>
                    <h1 style={{ fontWeight: "normal" }}>Edition description</h1>

                    <div className="col-12">
                        <div className="card">
                            <h5>Members</h5>
                            <MultiSelect value={members} options={[...(idols?.result || []), ...(group?.members || [])]} optionLabel="displayName" onChange={(e) => setMembers(e.value)} filter itemTemplate={listItemInSelect} onFilter={(e) => setSearchIdolInput(e.filter)} />

                            <DataView value={members} itemTemplate={(line) => listItem(line)} />

                            <div>
                                <Button label="Annuler" icon="pi pi-times" onClick={() => callback(false)} iconPos="right" className="mr-2 mb-2 p-button-danger" />
                                <Button label="Valider" icon="pi pi-check" onClick={() => onValidate()} loading={false} iconPos="right" className="mr-2 mb-2" />
                            </div>
                        </div>
                    </div>
                </Sidebar>
            )}

            <Toast ref={toast} />
        </>
    );
};

export default ModalMembersEditor;
