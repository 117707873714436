import React, { useState, useRef, useEffect } from "react";

import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";

import { AssetsService } from "../../service/AssetsService";

const ModalGallery = ({visible, callback}) => {
    const assetsService = new AssetsService();
    const [selectedFile, setSelectedFile] = useState({});
    const [currentPath, setCurrentPath] = useState("gallery");
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const op = useRef(null);

    const [layout, setLayout] = useState("grid");

    const useGallery = assetsService.useFetchGallery(currentPath);


    const updateBreadCrumb = (label) => {
        const splitedPath = currentPath.split("/");
        const index = splitedPath.findIndex((el) => el === label);
        const newPath = splitedPath.slice(0, index + 1).join("/");
        setCurrentPath(newPath);
    };

    useEffect(() => {
        const splitedPath = currentPath.split("/");
        const items = [];
        splitedPath.map((p) => {
            items.push({ label: p, command: (event) => updateBreadCrumb(event.item.label) });
        });
        setBreadcrumbItems(items);
    }, useGallery?.data?.data);

    const dataviewHeader = (
        <div className="grid grid-nogutter">
            <div className="col-3">
                <span className="p-input-icon-right">
                    <InputText type="text" placeholder="Search" onChange={(e) => setSearchInput(e.target.value)} />
                    <i className="pi pi-search" />
                </span>
            </div>
            <div className="col-6"></div>
            <div className="col-3" style={{ textAlign: "right" }}>
                <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
            </div>
        </div>
    );

    const itemTemplate = (data, layout) => {
        if (!data) {
            return;
        }
        if (layout === "list") {
            return dataviewListItem(data);
        } else if (layout === "grid") {
            return dataviewGridItem(data);
        }
    };

    const onDocClick = (data) => {
        if (data?.type === "directory") {
            setCurrentPath(data?.directory);
        }else{
            setSelectedFile(data)
        }
    };

    const dataviewListItem = (data) => {
        const selected = JSON.stringify(data) === JSON.stringify(selectedFile)
        return (
            <div className="col-12" onClick={() => onDocClick(data)} >
                {data.type === "directory" && (
                    <div className="flex flex-column md:flex-row align-items-center p-3 w-full" style={{ cursor: "pointer"}}>
                        <img src={"assets/file.png"} style={{ maxWidth: "20%", maxHeight: "30px", marginRight: "30px" }} />
                        <p>{data.name}</p>
                    </div>
                )}
                {data.type !== "directory" && (
                    <div className="flex flex-column md:flex-row align-items-center w-full" style={{ cursor: "pointer", border: selected ? "2px solid green": "0px" }}>
                        <img src={data.thumbnail} style={{ maxWidth: "20%", maxHeight: "50px", margin: "30px" }} />
                        <p>{data.name}</p>
                    </div>
                )}
            </div>
        );
    };

    const dataviewGridItem = (data) => {
        const selected = JSON.stringify(data) === JSON.stringify(selectedFile)
        return (
            <div className="col-12 md:col-2" onClick={() => onDocClick(data)} >
                {data.type === "directory" && (
                    <div style={{ cursor: "pointer" }}>
                        <img src={"assets/file.png"} className="my-4 md:my-0 w-9 " />
                        <p>{data.name}</p>
                    </div>
                )}
                {data.type !== "directory" && (
                    <div className="card m-3 border-1 surface-border flex flex-column align-items-center" style={{ cursor: "pointer", border: selected ? "2px solid green": "0px" }}>
                        <img src={data.thumbnail} style={{ maxWidth: "90%", maxHeight: "90%" }} />
                        <p>{data.name}</p>
                    </div>
                )}
            </div>
        );
    };
    const finderContainer = () => (
        <div className="grid mt-2">
            <div className="col-12">
                <DataView 
                gutter
                value={!!searchInput ? useGallery?.data?.data.filter((el) => el?.name?.toLowerCase()?.includes(searchInput.toLowerCase())) : useGallery?.data?.data}
                 layout={layout} 
                 itemTemplate={itemTemplate}
                header={dataviewHeader} />
            </div>
        </div>
    );

    const breadcrumbHome = { icon: "pi pi-home", command: () => setCurrentPath("gallery") };

    return (
        <>
            {visible && (
                <Sidebar visible={visible} onHide={() => callback(false)} baseZIndex={1000} fullScreen>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card card-w-title">
                                <h5>Gallery</h5>
                                <BreadCrumb home={breadcrumbHome} model={breadcrumbItems} onClick={(e) => console.log("EEEEE: ", e)} />
                                {finderContainer()}
                            </div>
                        </div>
                    </div>
                    <div>
                            <Button label="Annuler" icon="pi pi-times" onClick={() => callback(false)} iconPos="right" className="mr-2 mb-2 p-button-danger" />
                            <Button label="Selectionner" icon="pi pi-check" onClick={() => callback(selectedFile)} loading={false} iconPos="right" className="mr-2 mb-2" />
                        </div>
                </Sidebar>
            )}
        </>
    );
};

export default ModalGallery;
