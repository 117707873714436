import { gql, useQuery, useMutation } from "@apollo/client";

const GET_USER = gql`
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            uid
            email
            language
            notification
            offset
            token
            username
            avatar
            basicTopic
        }
    }
`;
const ADD_USER = gql`
    mutation addUser($user: UserInput) {
        addUser(user: $user) {
            id
            uid
            email
            language
            notification
            offset
            token
            username
            avatar
            basicTopic
        }
    }
`;
const UPDATE_USER = gql`
    mutation updateUser($id: ID!, $user: UserInput) {
        updateUser(id: $id, user: $user) {
            id
            uid
            email
            language
            notification
            offset
            token
            username
            avatar
            basicTopic
        }
    }
`;

const DELETE_USER = gql`
    mutation deleteUser($id: ID!) {
        deleteUser(id: $id)
    }
`;

const SEARCH_USERS = gql`
    query GetUsers($filters: UserFilter, $page: Int, $rows: Int, $sort: Sort) {
        getUsers(filters: $filters, page: $page, rows: $rows, sort: $sort) {
            total
            result {
                id
                uid
                email
                language
                notification
                offset
                token
                username
                avatar
                basicTopic
            }
        }
    }
`;

export class UsersService {
    useGetUsersQuery = ({ filters, page, rows, sort }) => {
        return useQuery(SEARCH_USERS, {
            variables: {
                filters,
                page,
                rows,
                sort,
            },
        });
    };
    useGetUserByIdQuery = (id) => {
        return useQuery(GET_USER, {
            variables: {
                id,
            },
        });
    };
    useDeleteUserGraphQL = (id) => {
        return useMutation(DELETE_USER);
    };
    useAddUserGraphQL = () => {
        return useMutation(ADD_USER);
    };

    useUpdateUserGraphQL = () => {
        return useMutation(UPDATE_USER);
    };
}
