import axios from "axios";
import { useMutation, useQuery } from 'react-query'

export class AssetsService {
    useFetchGallery = (path) => {
        return useQuery(
          'gallery' + path,
          async () => {
            try {
                const URL = "https://assets.s2mtechnologies.com";
                const getUrl = URL + "/files?directory=" + path;
              return await axios.get(getUrl)
            } catch (err) {
              console.log(err)
            }
          }
        )
    }
    usePushNewDirectory = () => {
      return useMutation(async ({path, label}) => {
        try {
          const URLserver = "https://assets.s2mtechnologies.com/directory?directory=" + path + "/" + label
          console.log("URL: ", URLserver)
          await axios.post(URLserver)
        } catch (error) {
          console.log(error)
        }
      })
    }
    useAddAssets = () => {
      return useMutation(async ({path, assets}) => {
        try {
          const URLthumbnailServer = "https://assets.s2mtechnologies.com/upload?thumbnail=true&directory=" + path
          console.log("path: ", path)
          console.log("assets: ", assets)
          for (let i = 0; i < assets.length; i++) {
            const newFile = assets[i]
            const formData = new FormData();
            formData.append(
                "file",
                newFile,
                newFile.name
            );
            await axios.post(URLthumbnailServer, formData)
        }
         
        } catch (error) {
          console.log(error)
        }
      })
    }
}
