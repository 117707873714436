import { gql, useQuery, useMutation } from "@apollo/client";

const GET_GROUP = gql`
    query GetGroup($id: ID!) {
        getGroup(id: $id) {
            id
            avatar
            banner
            shortDescription
            creationDate
            updateDate
            description
            followers
            agency
            debut
            name
            nameKorean
            fandom
            rate
            activeCategories
            members {
                id
                avatar
                displayName
            }
        }
    }
`;
const ADD_GROUP = gql`
    mutation addGroup($group: GroupInput) {
        addGroup(group: $group) {
            id
            avatar
            banner
            shortDescription
            creationDate
            updateDate
            description
            followers
            agency
            debut
            name
            nameKorean
            fandom
            rate
            activeCategories
            members {
                id
                avatar
                displayName
            }
        }
    }
`;
const UPDATE_GROUP = gql`
    mutation updateGroup($id: ID!, $group: GroupInput) {
        updateGroup(id: $id, group: $group) {
            id
            avatar
            banner
            shortDescription
            creationDate
            updateDate
            description
            followers
            agency
            debut
            name
            nameKorean
            fandom
            rate
            activeCategories
            members {
                id
                avatar
                displayName
            }
        }
    }
`;

const DELETE_GROUP = gql`
    mutation deleteGroup($id: ID!) {
        deleteGroup(id: $id)
    }
`;

const SEARCH_GROUPS = gql`
    query GetGroups($filters: GroupFilter, $page: Int, $rows: Int, $sort: Sort) {
        getGroups(filters: $filters, page: $page, rows: $rows, sort: $sort) {
            total
            result {
                id
                avatar
                banner
                shortDescription
                creationDate
                updateDate
                description
                followers
                agency
                debut
                name
                nameKorean
                fandom
                rate
                activeCategories
                members {
                    id
                    avatar
                    displayName
                }
            }
        }
    }
`;

export class GroupsService {
    useGetGroupsQuery = ({ filters, page, rows, sort }) => {
        return useQuery(SEARCH_GROUPS, {
            variables: {
                filters,
                page,
                rows,
                sort,
            },
        });
    };
    useGetGroupByIdQuery = (id) => {
        return useQuery(GET_GROUP, {
            variables: {
                id,
            },
        });
    };
    useDeleteGroupGraphQL = (id) => {
        return useMutation(DELETE_GROUP);
    };
    useAddGroupGraphQL = () => {
        return useMutation(ADD_GROUP);
    };

    useUpdateGroupGraphQL = () => {
        return useMutation(UPDATE_GROUP);
    };
}
