import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { InputMask } from "primereact/inputmask";

import { getWeekFromDate } from "../../utils/utils";

import { LaddersService } from "../../service/LaddersServices"

import ModalGallery from "../modals/ModalGallery"

const ModalLadderCreator = ({ visible, callback }) => {
    const laddersService = new LaddersService();
    const toast = useRef();

    const [image, setImage] = useState("");
    const [type, setType] = useState("");
    const [beginDate, setBeginDate] = useState("");
    const [endingDate, setEndingDate] = useState("");
    const [title, setTitle] = useState("");
    const [showGalleryModal, setShowGalleryModal] = useState(false);

    const [addLaddersGraphQL, hookAddLadder] = laddersService.useAddLadderGraphQL();

    

    const onCreateLadder = () => {
        const begin = new Date(beginDate);
        const ending = new Date(endingDate);
        const newLadder = {
            image: image,
            title: title,
            creationDate: Date.now(),
            beginDate: begin.getTime(),
            endingDate: ending.getTime(),
            type: type,
            voteNbr: 0,
        };
        addLaddersGraphQL({ variables: { Ladder: newLadder } })
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Successful", detail: "Idol added", life: 2000 });
                callback(false);
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error.message, life: 3000 });
            });
    };

    return (
        <>
            <Sidebar visible={visible} onHide={() => callback(false)} baseZIndex={1000} fullScreen>
                <h1 style={{ fontWeight: "normal" }}>Création d'un Ladder</h1>
                <div className="col-12">
                    <div className="card">
                        <div className="grid p-fluid mt-3">
                            <div className="field col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="title">Titre</label>
                                    <InputText type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} className={!!title ? "" : "p-invalid"} />
                                </div>
                            </div>

                            <div className="col-12 md:col-4">
                                <div className="field-radiobutton">
                                    <label htmlFor="man">Idol</label>
                                    <RadioButton inputId="IDOL" name="IDOL" value="IDOL" checked={type === "IDOL"} onChange={(e) => setType(e.target.value)} className={!!type ? "" : "p-invalid"} />
                                </div>
                                <div className="field-radiobutton">
                                    <label htmlFor="woman">Group</label>
                                    <RadioButton inputId="GROUP" name="GROUP" value="GROUP" checked={type === "GROUP"} onChange={(e) => setType(e.target.value)} className={!!type ? "" : "p-invalid"} />
                                </div>
                            </div>
                            <div className="field col-12 md:col-6">
                                <label htmlFor="beginDate">Date de debut</label>
                                <InputMask id="beginDate" mask="9999/99/99" value={beginDate} onChange={(e) => setBeginDate(e.value)} className={!!beginDate ? "" : "p-invalid"} />
                            </div>
                            <div className="field col-12 md:col-6">
                                <label htmlFor="endingDate">Date de Fin</label>
                                <InputMask id="endingDate" mask="9999/99/99" value={endingDate} onChange={(e) => setEndingDate(e.value)} className={!!endingDate ? "" : "p-invalid"} />
                            </div>
                            <div>
                                <label htmlFor="avatar">Image</label>
                                <Button label="Choisir" icon="pi pi-image" onClick={() => setShowGalleryModal(true)} loading={false} iconPos="right" className="mr-2 mb-2" />
                                {!!image && <img src={image} style={{maxHeight: "200px" }}/>}
                            </div>
                        </div>
                        <div>
                            <Button label="Annuler" icon="pi pi-times" onClick={() => callback(false)} iconPos="right" className="mr-2 mb-2 p-button-danger" />
                            <Button label="Créer" icon="pi pi-check" onClick={() => onCreateLadder()} loading={false} iconPos="right" className="mr-2 mb-2" />
                        </div>
                    </div>
                </div>
            </Sidebar>
            <ModalGallery visible={showGalleryModal} callback={value => {
                if(!value){
                    setShowGalleryModal(false)
                }else{
                    setImage(value?.url)
                    setShowGalleryModal(false)
                }}}/>
            <Toast ref={toast} />
        </>
    );
};

export default ModalLadderCreator;
