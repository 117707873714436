
import React from "react";



function Avatar({src, alt, style, size}) {
    const initialStyle = { height: size ? size + "px" : "100px", width: size ? size + "px" : "100px", borderRadius: "50%", marginRight: "10px" }
    return  <img 
    src={src} 
    alt={alt} 
    style={style ? {...style, ...initialStyle} : initialStyle} 
    />

}

export {Avatar}