import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { AvatarGroup } from "primereact/avatargroup";
import { Avatar } from "primereact/avatar";

import { GroupsService } from "../../service/GroupsService";
import ModalGroupEditor from "../../customComponents/modals/ModalGroupEditor";
import ModalGroupCreator from "../../customComponents/modals/ModalGroupCreator";

const GroupsPage = () => {
    const groupsService = new GroupsService();
    const [displayConfirmation, setDisplayConfirmation] = useState(false);

    const history = useHistory();

    const toast = useRef();
    const timer = useRef(null);
    const [createGroup, setCreateGroup] = useState(false);
    const [groupIdToEdit, setGroupIdToEdit] = useState("");
    const [groupIdToDelete, setGroupIdToDelete] = useState("");
    const [searchGroupInput, setSearchGroupInput] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentRows, setCurrentRows] = useState(20);
    const [sortGroups, setSortGroups] = useState({ field: "creationDate", value: "desc" });
    const [filterGroups, setFilterGroups] = useState({
        name: { includes: searchGroupInput },
    });
    const searchGroupsGraphQL = groupsService.useGetGroupsQuery({ filters: filterGroups, sort: sortGroups, rows: currentRows, operator: "or", page: currentPage });

    const [deleteGroupGraphQl, hookDeleteGroup] = groupsService.useDeleteGroupGraphQL();

    const groups = searchGroupsGraphQL?.data?.getGroups;

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            const filtersTmp = {
                name: { includes: searchGroupInput },
            };
            setFilterGroups(filtersTmp);
        }, 1000);
        return () => clearTimeout(timer.current);
    }, [searchGroupInput]);

    const confirmationDialogFooter = (line) => {
        return (
            <>
                <Button type="button" label="Non" icon="pi pi-times" onClick={() => setGroupIdToDelete("")} className="p-button-text" />
                <Button
                    type="button"
                    label="Oui"
                    icon="pi pi-check"
                    onClick={() => {
                        setGroupIdToDelete("");
                        deleteGroup(line);
                    }}
                    className="p-button-text"
                    autoFocus
                />
            </>
        );
    };

    const onPageInputChange = (event) => {
        console.log(event);
        setCurrentRows(event.rows);
        setCurrentPage(event.page + 1);
    };

    const bodyNameTemplate = (rowData) => {
        return <p>{rowData.name + " " + rowData.nameKorean} </p>;
    };
    const bodyFollowersTemplate = (rowData) => {
        return <p>{rowData.followers}</p>;
    };
    const bodyRateTemplate = (rowData) => {
        return <p>{rowData.rate}</p>;
    };
    const bodyAvatarTemplate = (rowData) => {
        return <Image src={rowData.avatar} alt={rowData.name} width={150} preview />;
    };

    const bodyLastUpdateTemplate = (rowData) => {
        const date = new Date(rowData.updateDate);
        return <p>{date?.toLocaleDateString()}</p>;
    };

    const bodyMembersTemplate = (rowData) => {
        if (rowData?.members?.length > 0) {
            return (
                <AvatarGroup className="mb-3">
                    {rowData?.members?.map((member) => {
                        return <Avatar image={member?.avatar} size="large" shape="circle" />;
                    })}
                </AvatarGroup>
            );
        } else {
            return <p>None</p>;
        }
    };
    const bodyActionsTemplate = (rowData) => {
        return (
            <>
                <Button icon="pi pi-share-alt" className="p-button-rounded p-button-outlined p-button-success mr-2 mb-2" tooltip="Share" onClick={() => console.log("share")} />
                <Button icon="pi pi-plus" className="p-button-rounded p-button-outlined mr-2 mb-2" tooltip="See More" onClick={() => history.push("/group/" + rowData?.id)} />
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-outlined mr-2 mb-2"
                    tooltip="Edit"
                    onClick={(e) => {
                        e.stopPropagation();
                        setGroupIdToEdit(rowData?.id);
                    }}
                />
                {groupIdToEdit === rowData.id && (
                    <ModalGroupEditor
                        visible={groupIdToEdit === rowData.id}
                        callback={(value) => {
                            if (value) {
                                searchGroupsGraphQL.refetch();
                            }

                            setGroupIdToEdit("");
                        }}
                        group={rowData}
                    />
                )}
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined mr-2 mb-2" tooltip="Delete" onClick={() => setGroupIdToDelete(rowData?.id)} />
                {groupIdToDelete === rowData.id && (
                    <Dialog header="Confirmation" visible={groupIdToDelete === rowData.id} onHide={() => setGroupIdToDelete("")} style={{ width: "350px" }} modal footer={confirmationDialogFooter(rowData)}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            <span>{"Etes vous sur de vouloir supprimer : " + rowData?.displayName + "?"}</span>
                        </div>
                    </Dialog>
                )}
            </>
        );
    };

    const headerGroupsContainer = () => {
        return (
            <div className="p-fluid formgrid grid">
                <div className="field col-12 md:col-2">
                    <Button icon="pi pi-plus" style={{ marginRight: ".5em" }} label="Ajouter un Group" className="" onClick={() => setCreateGroup(!createGroup)}></Button>
                </div>
                <div className="col-12 mb-2 lg:col-6 lg:mb-0" />
                <div className="col-12 mb-2 lg:col-4 lg:mb-0">
                    <span className="p-input-icon-right">
                        <InputText type="text" placeholder="Search" onChange={(e) => setSearchGroupInput(e.target.value)} />
                        <i className="pi pi-search" />
                    </span>
                </div>
            </div>
        );
    };

    const deleteGroup = (group) => {
        deleteGroupGraphQl({ variables: { id: group.id } })
            .then((res) => {
                searchGroupsGraphQL.refetch();
                toast.current.show({ severity: "success", summary: "Successful", detail: "Group deleted", life: 2000 });
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error.message, life: 3000 });
            });
    };

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Groups</h5>
                        {headerGroupsContainer()}
                        <DataTable
                            value={groups?.result}
                            totalRecords={groups?.total}
                            onPage={(p) => onPageInputChange(p)}
                            lazy
                            paginator
                            rows={currentRows}
                            rowsPerPageOptions={[10, 20, 50]}
                            first={(currentPage - 1) * currentRows}
                            scrollable
                            loading={searchGroupsGraphQL.loading}
                            scrollDirection="both"
                            className="mt-3"
                            sortField={sortGroups.field}
                            sortOrder={sortGroups.value === "asc" ? 1 : -1}
                            onSort={(e) => setSortGroups({ field: e.sortField, value: e.sortOrder === 1 ? "asc" : "desc" })}
                        >
                            <Column header="Image" style={{ width: "15%" }} body={(line) => bodyAvatarTemplate(line)} />
                            <Column field="name" sortable header="Name" style={{ width: "20%" }} body={(line) => bodyNameTemplate(line)} />
                            <Column field="followers" sortable header="Followers" style={{ width: "10%" }} body={(line) => bodyFollowersTemplate(line)} />
                            <Column field="rate" sortable header="Rate" style={{ width: "10%" }} body={(line) => bodyRateTemplate(line)} />
                            <Column field="updateDate" sortable header="Last Update" style={{ width: "10%" }} body={(line) => bodyLastUpdateTemplate(line)} />
                            <Column header="Members" style={{ width: "20%" }} body={(line) => bodyMembersTemplate(line)} />
                            <Column header="Actions" style={{ width: "15%" }} body={(line) => bodyActionsTemplate(line)} />
                        </DataTable>
                    </div>
                </div>
            </div>
            <ModalGroupCreator
                visible={createGroup}
                callback={(value) => {
                    searchGroupsGraphQL.refetch();
                    setCreateGroup(value);
                }}
            />

            <Toast ref={toast} />
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(GroupsPage, comparisonFn);
