import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";

import { IdolsService } from "../../../service/IdolsService";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { convertToHTML } from "draft-convert";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const ModalDescriptionEditor = ({ visible, callback, idol }) => {
    const idolsService = new IdolsService();
    const toast = useRef();
    const [updateIdolGraphQL, hookUpdateIdol] = idolsService.useUpdateIdolGraphQL();

    const [convertedContent, setConvertedContent] = useState(null);

    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(idol?.description || ""))));

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    };
    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    };

    const onValidate = () => {
        const newObj = {
            description: convertedContent,
        };
        updateIdolGraphQL({ variables: { id: idol.id, idol: newObj } })
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Successful", detail: "Idol Updated", life: 2000 });
                callback(false);
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error.message, life: 3000 });
            });
    };
    return (
        <>
            {visible && (
                <Sidebar visible={visible} onHide={() => callback(false)} baseZIndex={1000} fullScreen>
                    <h1 style={{ fontWeight: "normal" }}>Edition description</h1>

                    <div className="col-12">
                        <div className="card">
                            <div className="grid p-fluid mt-3">
                                <div className="field col-12 md:col-12">
                                    <div className="field">
                                        <Editor defaultEditorState={editorState} wrapperClassName="wrapper-class" editorClassName="editor-class" toolbarClassName="toolbar-class" onEditorStateChange={handleEditorChange} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Button label="Annuler" icon="pi pi-times" onClick={() => callback(false)} iconPos="right" className="mr-2 mb-2 p-button-danger" />
                                <Button label="Valider" icon="pi pi-check" onClick={() => onValidate()} loading={false} iconPos="right" className="mr-2 mb-2" />
                            </div>
                        </div>
                    </div>
                </Sidebar>
            )}

            <Toast ref={toast} />
        </>
    );
};

export default ModalDescriptionEditor;
