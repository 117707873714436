import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Tag } from "primereact/tag";
import { ProgressBar } from "primereact/progressbar";

import { LaddersService } from "../../service/LaddersServices";
import ModalLadderEditor from "../../customComponents/modals/ModalLadderEditor";
import ModalLadderCreator from "../../customComponents/modals/ModalLadderCreator";

const fakeLadders = [
    {
        image: "https://assets.s2mtechnologies.com/images/gallery/wallpapers/black/aditya-vyas-RQ2va_6kGh0-unsplash.jpg",
        title: "Best Dancer 2022",
        voteNbr: 122043,
        beginDate: 1663108006861,
        endingDate: 1672444800000,
        status: "INPROGRESS",
        type: "GROUP",
        creationDate: 1663108006861,
    },
    {
        image: "https://assets.s2mtechnologies.com/images/gallery/wallpapers/black/aditya-vyas-RQ2va_6kGh0-unsplash.jpg",
        title: "Best Dancer 2022",
        voteNbr: 122043,
        beginDate: 1663108006861,
        endingDate: 1672444800000,
        type: "IDOL",
        status: "DONE",
        creationDate: 1663108006861,
    },
    {
        image: "https://assets.s2mtechnologies.com/images/gallery/wallpapers/black/aditya-vyas-RQ2va_6kGh0-unsplash.jpg",
        title: "Best Dancer 2022",
        voteNbr: 122043,
        beginDate: 1663108006861,
        endingDate: 1672444800000,
        type: "IDOL",
        status: "DONE",
        creationDate: 1663108006861,
    },
    {
        image: "https://assets.s2mtechnologies.com/images/gallery/wallpapers/black/aditya-vyas-RQ2va_6kGh0-unsplash.jpg",
        title: "Best Dancer 2022",
        voteNbr: 122043,
        beginDate: 1663108006861,
        endingDate: 1672444800000,
        type: "GROUP",
        status: "NOTSTARTED",
        creationDate: 1663108006861,
    },
];

const LadderPage = () => {
    const laddersService = new LaddersService();
    const [displayConfirmation, setDisplayConfirmation] = useState(false);

    const history = useHistory();

    const toast = useRef();
    const timer = useRef(null);
    const [createLadder, setCreateLadder] = useState(false);
    const [ladderIdToEdit, setLadderIdToEdit] = useState("");
    const [ladderIdToDelete, setLadderIdToDelete] = useState("");
    const [searchLadderInput, setSearchLadderInput] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentRows, setCurrentRows] = useState(20);
    const [sortLadders, setSortLadders] = useState({ field: "creationDate", value: "desc" });
    const [filterLadders, setFilterLadders] = useState([]);
    const searchLaddersGraphQL = laddersService.useSearchQueryGraphQL({  });

    const [deleteLadderGraphQl, hookDeleteLadder] = laddersService.useDeleteLadderGraphQL();

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            const filtersTmp = [];
            filtersTmp.push({ field: "title", value: searchLadderInput });
            setFilterLadders(filtersTmp);
        }, 1000);
        return () => clearTimeout(timer.current);
    }, [searchLadderInput]);

    useEffect(() => {}, [sortLadders]);

    const confirmationDialogFooter = (line) => {
        return (
            <>
                <Button type="button" label="Non" icon="pi pi-times" onClick={() => setLadderIdToDelete("")} className="p-button-text" />
                <Button
                    type="button"
                    label="Oui"
                    icon="pi pi-check"
                    onClick={() => {
                        setLadderIdToDelete("");
                        deleteLadder(line);
                    }}
                    className="p-button-text"
                    autoFocus
                />
            </>
        );
    };

    const bodyTitleTemplate = (rowData) => {
        return <p>{rowData.title}</p>;
    };
    const bodyVoteNbrTemplate = (rowData) => {
        return <p>{rowData.voteNbr}</p>;
    };
    const bodyStatusTemplate = (rowData) => {
        if (rowData.status === "DONE") return <Tag className="mr-2" severity="primary" value="Terminée" rounded />;
        if (rowData.status === "NOTSTARTED") return <Tag className="mr-2" severity="primary" value="Non commencé" rounded />;
        if (rowData.status === "INPROGRESS") return <Tag className="mr-2" severity="primary" value="En Cours" rounded />;
        return <Tag className="mr-2" severity="info" value={rowData.status} rounded />;
    };
    const bodyTypeTemplate = (rowData) => {
        return rowData.type === "IDOL" ? <Tag className="mr-2" severity="primary" value="Idol" rounded /> : <Tag className="mr-2" severity="primary" value="Group" rounded />;
    };
    const bodyImageTemplate = (rowData) => {
        return <Image src={rowData.image} alt={rowData.title} width={150} preview />;
    };

    const bodyLastUpdateTemplate = (rowData) => {
        const date = new Date(rowData.creationDate);
        return <p>{date.toLocaleDateString()}</p>;
    };
    const bodyDateTemplate = (rowData) => {
        const beginDate = new Date(rowData.beginDate);
        const endingDate = new Date(rowData.endingDate);
        const diff = Date.now() - rowData.beginDate;
        const value = diff >= 0 ? Math.round(rowData.endingDate / (diff * 100)) : 0
        return (
            <div style={{ display: "flex", flexDirection: 'column', width: '100%' }}>
                <div style={{ display: "flex", flexDirection: "row", width: '100%', justifyContent: 'space-between' }}>
                    <p>{beginDate.toLocaleDateString()}</p>
                    <p>{endingDate.toLocaleDateString()}</p>
                </div>
                <div style={{ width: '100%' }}>
                    <ProgressBar value={value} />
                </div>
            </div>
        );
    };
    const bodyActionsTemplate = (rowData) => {
        return (
            <>
                <Button icon="pi pi-share-alt" className="p-button-rounded p-button-outlined p-button-success mr-2 mb-2" tooltip="Share" onClick={() => console.log("share")} />
                <Button icon="pi pi-plus" className="p-button-rounded p-button-outlined  p-button-secondary mr-2 mb-2" tooltip="See More" onClick={() => history.push("/ladderEvent/" + rowData.id)} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-outlined mr-2 mb-2" tooltip="Edit" onClick={() => setLadderIdToEdit(rowData?.id)} />
                {ladderIdToEdit === rowData.id && (
                    <ModalLadderEditor
                        visible={ladderIdToEdit === rowData.id}
                        callback={() => {
                            setLadderIdToEdit("");
                        }}
                        ladder={rowData}
                    />
                )}
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined mr-2 mb-2" tooltip="Delete" onClick={() => setLadderIdToDelete(rowData?.id)} />
                {ladderIdToDelete === rowData.id && (
                    <Dialog header="Confirmation" visible={ladderIdToDelete === rowData.id} onHide={() => setLadderIdToDelete("")} style={{ width: "350px" }} modal footer={confirmationDialogFooter(rowData)}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            <span>{"Etes vous sur de vouloir supprimer le ladder => " + rowData?.title + "?"}</span>
                        </div>
                    </Dialog>
                )}
            </>
        );
    };

    const headerLaddersContainer = () => {
        return (
            <div className="p-fluid formgrid grid">
                <div className="field col-12 md:col-2">
                    <Button icon="pi pi-plus" style={{ marginRight: ".5em" }} label="Ajouter un ladder" className="" onClick={() => setCreateLadder(!createLadder)}></Button>
                </div>
                <div className="col-12 mb-2 lg:col-6 lg:mb-0" />
                <div className="col-12 mb-2 lg:col-4 lg:mb-0">
                    <span className="p-input-icon-right">
                        <InputText type="text" placeholder="Search" onChange={(e) => setSearchLadderInput(e.target.value)} />
                        <i className="pi pi-search" />
                    </span>
                </div>
            </div>
        );
    };

    const deleteLadder = (ladder) => {
        deleteLadderGraphQl({ variables: { id: ladder.id } })
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Successful", detail: "Ladder deleted", life: 2000 });
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: error.message, life: 3000 });
            });
    };

    const onPageInputChange = (event) => {
        console.log(event);
        setCurrentRows(event.rows);
        setCurrentPage(event.page + 1);
    };
    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Ladders</h5>
                        {headerLaddersContainer()}
                        <DataTable 
                        value={fakeLadders}
                        totalRecords={fakeLadders?.length}
                        onPage={(p) => onPageInputChange(p)}
                        lazy
                        paginator
                        rows={currentRows}
                        first={(currentPage - 1) * currentRows}
                        rowsPerPageOptions={[10, 20, 50]}
                        scrollable 
                        scrollDirection="both" 
                        className="mt-3">
                            <Column field="image" header="Image" style={{ width: "15%" }} body={(line) => bodyImageTemplate(line)} />
                            <Column field="title" header="Titre" style={{ width: "10%" }} body={(line) => bodyTitleTemplate(line)} />
                            <Column field="voteNbr" header="Nombre de votes" style={{ width: "10%" }} body={(line) => bodyVoteNbrTemplate(line)} />
                            <Column field="creationDate" header="Last Update" style={{ width: "10%" }} body={(line) => bodyLastUpdateTemplate(line)} />
                            <Column field="type" header="Type" style={{ width: "10%" }} body={(line) => bodyTypeTemplate(line)} />
                            <Column field="status" header="Statut" style={{ width: "10%" }} body={(line) => bodyStatusTemplate(line)} />
                            <Column header="Dates" style={{ width: "20%" }} body={(line) => bodyDateTemplate(line)} />
                            <Column header="Actions" style={{ width: "15%" }} body={(line) => bodyActionsTemplate(line)} />
                        </DataTable>
                    </div>
                </div>
            </div>
            <ModalLadderCreator
                visible={createLadder}
                callback={(value) => {
                    searchLaddersGraphQL.refetch();
                    setCreateLadder(value);
                }}
            />

            <Toast ref={toast} />
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(LadderPage, comparisonFn);
