import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { InputMask } from "primereact/inputmask";
import moment from "moment";

import { getWeekFromDate } from "../../utils/utils";

import { IdolsService } from "../../service/IdolsService";

import ModalGallery from "../modals/ModalGallery";

const ModalIdolEditor = ({ visible, callback, idol }) => {
    const idolsService = new IdolsService();
    const toast = useRef();
    const [updateIdolGraphQL, hookUpdateIdol] = idolsService.useUpdateIdolGraphQL();

    const [sexe, setSexe] = useState(idol?.sexe || "");
    const [group, setGroup] = useState(idol?.group || "");
    const [realName, setRealName] = useState(idol?.realName || "");
    const [displayName, setDisplayName] = useState(idol?.displayName || "");
    const [dateOfBirth, setDateOfBirth] = useState(moment(idol?.dateOfBirth).format("YYYY/MM/DD") || "");
    const [avatar, setAvatar] = useState(idol?.avatar || "");
    const [shortDescription, setShortDescription] = useState(idol?.shortDescription || "");

    const [showGalleryModal, setShowGalleryModal] = useState(false);

    const onEditIdol = () => {
        const birthDate = new Date(dateOfBirth);
        const new_idol = {
            avatar: avatar,
            birthDay: birthDate.getDate(),
            birthWeek: getWeekFromDate(dateOfBirth),
            birthMonth: birthDate.getMonth() + 1,
            birthYear: birthDate.getFullYear(),
            dateOfBirth: birthDate.getTime(),
            displayName: displayName,
            realName: realName,
            group: group,
            likes: idol?.likes || 0,
            shortDescription: shortDescription,
            followers: idol?.followers || 0,
            sexe: sexe,
        };
        updateIdolGraphQL({ variables: { id: idol.id, idol: new_idol } })
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Successful", detail: "Idol Updated", life: 2000 });
                callback(true);
            })
            .catch((error) => {
                callback(false);
                toast.current.show({ severity: "error", summary: "Error", detail: error.message, life: 3000 });
            });
    };
    return (
        <>
            {visible && (
                <Sidebar visible={visible} dismissable={false} baseZIndex={1000} fullScreen>
                    <h1 style={{ fontWeight: "normal" }}>Edition d'un idol</h1>
                    <div className="col-12">
                        <div className="card">
                            <div className="grid p-fluid mt-3">
                                <div className="field col-12 md:col-6">
                                    <div className="field">
                                        <label htmlFor="displayName">Display Name</label>
                                        <InputText type="text" id="displayName" value={displayName} onChange={(e) => setDisplayName(e.target.value)} className={!!displayName ? "" : "p-invalid"} />
                                    </div>
                                </div>
                                <div className="field col-12 md:col-6">
                                    <div className="field">
                                        <label htmlFor="realName">Real Name</label>
                                        <InputText type="text" id="realName" value={realName} onChange={(e) => setRealName(e.target.value)} className={!!realName ? "" : "p-invalid"} />
                                    </div>
                                </div>
                                <div className="field col-12 md:col-6">
                                    <div className="field">
                                        <label htmlFor="group">Group</label>
                                        <InputText type="text" id="group" value={group} onChange={(e) => setGroup(e.target.value)} className={!!group ? "" : "p-invalid"} />
                                    </div>
                                </div>
                                <div className="field col-12 md:col-6">
                                    <div className="field">
                                        <label htmlFor="shortDescription">Small Description</label>
                                        <InputText type="text" id="shortDescription" value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} className={!!shortDescription ? "" : "p-invalid"} />
                                    </div>
                                </div>

                                <div className="col-12 md:col-4">
                                    <div className="field-radiobutton">
                                        <label htmlFor="man">Man</label>
                                        <RadioButton inputId="man" name="man" value="M" checked={sexe === "M"} onChange={(e) => setSexe(e.target.value)} className={!!sexe ? "" : "p-invalid"} />
                                    </div>
                                    <div className="field-radiobutton">
                                        <label htmlFor="woman">Woman</label>
                                        <RadioButton inputId="woman" name="woman" value="F" checked={sexe === "F"} onChange={(e) => setSexe(e.target.value)} className={!!sexe ? "" : "p-invalid"} />
                                    </div>
                                </div>
                                <div className="field col-12 md:col-6">
                                    <label htmlFor="dateOfBirth">Date Of Birth</label>
                                    <InputMask id="dateOfBirth" mask="9999/99/99" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.value)} className={!!dateOfBirth ? "" : "p-invalid"} />
                                </div>
                                <div>
                                    <label htmlFor="avatar">Avatar</label>
                                    <Button label="Choisir" icon="pi pi-image" onClick={() => setShowGalleryModal(true)} loading={false} iconPos="right" className="mr-2 mb-2" />
                                    {!!avatar && <img src={avatar} style={{ maxHeight: "200px" }} />}
                                </div>
                            </div>
                            <div>
                                <Button label="Annuler" icon="pi pi-times" onClick={() => callback(false)} iconPos="right" className="mr-2 mb-2 p-button-danger" />
                                <Button label="Confirmer" icon="pi pi-check" onClick={() => onEditIdol()} loading={false} iconPos="right" className="mr-2 mb-2" />
                            </div>
                        </div>
                    </div>
                </Sidebar>
            )}
            <ModalGallery
                visible={showGalleryModal}
                callback={(value) => {
                    if (!value) {
                        setShowGalleryModal(false);
                    } else {
                        setAvatar(value?.url);
                        setShowGalleryModal(false);
                    }
                }}
            />
            <Toast ref={toast} />
        </>
    );
};

export default ModalIdolEditor;
