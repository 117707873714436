import React, {createContext, useEffect, useState} from 'react';
import { auth } from '../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth'

export const AuthContext = createContext()

export const AuthContextProvider = props => {
    const [user, setUser] = useState()
    const [error, setError] = useState()

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, setUser, setError)
        return () => unsubscribe()
    }, [])

    useEffect(async () => {
        if(!!user){
            const token = await user.getIdToken()
            localStorage.setItem("@token", token)
        }
    }, [user])


    return <AuthContext.Provider value={{ user, error }} {...props} />
}
