import { gql, useQuery, useMutation } from "@apollo/client";

const GET_GALLERY = gql`
    query GetGallery($id: ID!) {
        getGallery(id: $id) {
            id
            reference {
                ... on Group {
                    id
                    name
                }
                ... on Idol {
                    id
                    displayName
                }
            }
            modelType
            name
            url
            creationDate
            updateDate
        }
    }
`;
const ADD_GALLERY = gql`
    mutation addGallery($gallery: GalleryInput) {
        addGallery(gallery: $gallery) {
            id
            reference {
                ... on Group {
                    id
                    name
                }
                ... on Idol {
                    id
                    displayName
                }
            }
            modelType
            name
            url
            creationDate
            updateDate
        }
    }
`;
const UPDATE_GALLERY = gql`
    mutation updateGallery($id: ID!, $gallery: GalleryInput) {
        updateGallery(id: $id, gallery: $gallery) {
            id
            reference {
                ... on Group {
                    id
                    name
                }
                ... on Idol {
                    id
                    displayName
                }
            }
            modelType
            name
            url
            creationDate
            updateDate
        }
    }
`;

const DELETE_GALLERY = gql`
    mutation deleteGallery($id: ID!) {
        deleteGallery(id: $id)
    }
`;

const SEARCH_GALLERIES = gql`
    query GetGalleries($filters: GalleryFilter, $page: Int, $rows: Int, $sort: Sort) {
        getGalleries(filters: $filters, page: $page, rows: $rows, sort: $sort) {
            total
            result {
                id
                reference {
                    ... on Group {
                        id
                        name
                    }
                    ... on Idol {
                        id
                        displayName
                    }
                }
                modelType
                name
                url
                creationDate
                updateDate
            }
        }
    }
`;

export class GalleryService {
    useGetGalleriesQuery = ({ filters, page, rows, sort }) => {
        return useQuery(SEARCH_GALLERIES, {
            variables: {
                filters,
                page,
                rows,
                sort,
            },
        });
    };
    useGetGalleryByIdQuery = (id) => {
        return useQuery(GET_GALLERY, {
            variables: {
                id,
            },
        });
    };
    useDeleteGalleryGraphQL = (id) => {
        return useMutation(DELETE_GALLERY);
    };
    useAddGalleryGraphQL = () => {
        return useMutation(ADD_GALLERY);
    };

    useUpdateGalleryGraphQL = () => {
        return useMutation(UPDATE_GALLERY);
    };
}
