import React from "react";
import { Switch } from "react-router-dom";

import PrimeReact from "primereact/api";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";

import { AuthenticatedRoute } from "./routes/AuthenticatedRoute";
import DashboardPage from "./pages/DashboardPage";
import SignInPage from "./pages/SignInPage";
import { UnauthenticatedRoute } from "./routes/UnauthenticatedRoute";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

const queryClient = new QueryClient();

const httpLink = createHttpLink({
    uri: "https://api.johayo.io/graphql",
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("@token");
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
            project: "johayo"
        },
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

const App = () => {
    PrimeReact.ripple = true;

    return (
        <div>
            <QueryClientProvider client={queryClient}>
                <ApolloProvider client={client}>
                    <Switch>
                        <UnauthenticatedRoute path="/login" component={SignInPage} />
                        <AuthenticatedRoute path="/" component={DashboardPage} />
                    </Switch>
                </ApolloProvider>
            </QueryClientProvider>
        </div>
    );
};

export default App;
